export class dadosProdutos{
    public id:number
    public ststamp: string
    public produto: string
    public preco_civa: number
    public preco_siva: number
    public referencia: string
    public pesobruto: number
    public modelo: string
    public marca : string
    public familia : string
    public imagem:string
    public imagem1:string
    public imagem2:string
    public imagem3:string
    public descricao:string
    public descricao_site:string
    public estado:string
}