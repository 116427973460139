import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../header/login/login.component'
@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor( private dialog:MatDialog) { }

  abrirLogin(){ 
    if(this.dialog.openDialogs.length==0){
      this.dialog.open(LoginComponent, {
        height: '400px',
        width: '500px',
        disableClose:true})
    }
  }
 
}
