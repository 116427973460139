import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'
import { utilizadorService } from './utilizador.service'

@Injectable()

export class autenticado implements CanActivate{

    constructor(private autenticacao:utilizadorService) {}

    canActivate(): boolean {
        
        return this.autenticacao.autenticado()
    }
}