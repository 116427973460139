<div class="container">
  <div class="ordem-compra" >
    <div class="ordem-compra mt-2" *ngIf="estadoEncomenda === undefined">
      <mat-card class="mb-2">
        <div class="row align-contents-center">
          <div class="col-12 subtitulos p-0 pl-2 align-items-center">
            <i class="material-icons" style="vertical-align: middle;">shopping_cart</i> Efetuar Compra
          </div>
        </div>
      </mat-card>
      
      <mat-card *ngIf="mostrarLoading" class="col-12 Carregar" align="center">
        <img src="/assets/loading.gif">
        <h5> A Processar Encomenda</h5> 
      </mat-card>
      <mat-card *ngIf="!mostrarLoading">
        <mat-vertical-stepper linear >
          <ng-template matStepperIcon="number">
            <mat-icon>insert_drive_file</mat-icon>
          </ng-template>
          <!--Morada de Envio-->
          <mat-step #myStep  label="Selecionar Morada de Envio" [stepControl]="formMEnvio">
            <div class="container">
              <form [formGroup]="formMEnvio">
                <div class="row">
                  <mat-form-field class="col-8">
                    <mat-label>Morada de Envio</mat-label>
                    <mat-select formControlName="xEMorada" required (selectionChange)="dadosMorada($event)">
                      <div *ngFor="let MoradaEnvio of this.moradaEnvio">
                        <mat-option [value]="MoradaEnvio.id" >{{MoradaEnvio.descricao}}</mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </form>
              <div *ngIf="this.moradaSelecionada">
                <div class="row">
                  <div class="col-8">
                    <mat-card class="p-0">
                      <div class="p-2 bg-light subtitulos_cinzas">
                        Morada Selecionada
                      </div>
                      <mat-card-content class="p-3">
                        <div class="row p-0">
                          <mat-form-field class="col-12 p-0">
                            <input matInput readonly value="{{this.dados_moradaSelecionada.morada}}">
                          </mat-form-field>
                        </div>
                        <div class="row p-0">
                          <mat-form-field class="col-6 p-0">
                            <input matInput readonly value="{{this.dados_moradaSelecionada.localidade}}">
                          </mat-form-field>
                          <mat-form-field class="col-6 p-0">
                            <input matInput readonly value="{{this.dados_moradaSelecionada.codpostal}}">
                          </mat-form-field>
                        </div>
                        <div class="row">
                          <mat-form-field class="col-6 p-0">
                            <input matInput readonly value="{{this.dados_moradaSelecionada.distrito}}">
                          </mat-form-field>
                          <mat-form-field  class="col-6 p-0">
                            <input matInput readonly value="{{this.dados_moradaSelecionada.pais}}">
                          </mat-form-field>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>                    
              </div>
            </div>
            <div class="mt-2" align="right">
              <button matStepperNext mat-flat-button style="background-color:#00364d;color: white; font-size: 300;">Continuar</button>
            </div>
          </mat-step>
          <!--Metodo de Envio-->
          <mat-step label="Método de Envio" [stepControl]="formFEnvio">
            <form [formGroup]="formFEnvio">
              <div class="container">
                <div class="row">
                  <mat-radio-group formControlName="xMEnvio" required (change)="carregaMetodoEnvio($event)" >
                    <mat-radio-button style="padding-top: 10px;"  value="Levantamento na Loja">Levantamento na Loja</mat-radio-button><br>
                    <mat-radio-button style="padding-top: 10px;"  value="Envio CTT">Envio CTT</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </form>
            <div class="mt-2" align="right">
              <button matStepperNext mat-flat-button style="background-color:#00364d;color: white; font-size: 300;">Continuar</button>
            </div>
          </mat-step>
          <mat-step label="Método de Pagamento" [stepControl]="formMPagamento">
            <form [formGroup]="formMPagamento">
              <div class="container">
                <div class="row">
                  <mat-radio-group aria-label="" formControlName="xMPagamento">
                    <mat-radio-button value="Transferencia Bancária">Transferencia Bancária</mat-radio-button><br>
                    <mat-radio-button value="Pagamento por Multibanco">Pagamento por Multibanco</mat-radio-button><br>
                    <mat-radio-button value="Envio a Cobrança">Envio a Cobrança</mat-radio-button>
                  </mat-radio-group>
                  </div>
              </div>
            </form>
            <div class="mt-2" align="right">
              <button matStepperNext mat-flat-button style="background-color:#00364d;color: white; font-size: 300;">Continuar</button>
            </div>
          </mat-step>
          <mat-step label="Finalizar Encomenda"> <!-- [stepControl]="formResumo"-->
            <div class="container">
              <div class="row cabecalho d-flex align-content-center p-2 mt-2" style="background-color: #efefef;">
                <div class="col-1"></div>
                <div class="col-5">Artigo</div>
                <div class="col-1">Qtd</div>
                <div class="col-2" align="right">Preco Unit.</div>
                <div class="col-2" align="right">Total</div>
              </div>
              <div *ngIf="c_WS.listaCarrinho.length > 0 else carrinhoVazio" >
                <div *ngFor = "let dadosItens of c_WS.listaCarrinho" class="row  p-2" style="background: #fff; border-bottom: solid 1px #F0F0F0; vertical-align: center;">
                  <div class="col-1 d-flex align-items-center ">
                    <img src="https://imagens.asdepecas.pt//{{dadosItens.referencia}}.jpg" style="max-height: 25px;" class="img-fluid">
                  </div>
                  <div class="col-5 text-left d-flex align-items-center" style="vertical-align: middle;">
                    {{dadosItens.produto}}
                  </div>
          
                  <div class="col-1 d-flex align-items-center" align="center">
                        {{dadosItens.qtt}}
                  </div>
                  
                  <div class="col-2 d-flex align-items-center justify-content-end">
                    {{dadosItens.preco_civa | currency: 'EUR'}}
                  </div>
                  <div class="col-2 d-flex align-items-center justify-content-end">
                    {{dadosItens.total_civa | currency: 'EUR'}}
                  </div>
                </div>
              </div>
              <div class="row cabecalho d-flex align-content-center p-2" style="background-color: #efefef;">
                <div class="col-12 mt-3 subtitulos_cinzas" align="right">Sub-Total: {{c_WS.totalSIvaCarrinho | currency:'EUR'}}</div>
                <div class="col-12 mt-1 subtitulos_cinzas" align="right" *ngIf="c_WS.totalPortes>0">Portes: {{c_WS.totalPortes | currency:'EUR'}}</div>
                <div class="col-12 mt-1 subtitulos_cinzas" align="right">Iva(23%): {{c_WS.totalIVA | currency:'EUR'}}</div>
                <div class="col-12 mt-1 subtitulos_cinzas" align="right">Total: {{c_WS.totalCIvaCarrinho | currency:'EUR'}}</div>
              </div>
            </div>
            <div *ngIf="myStep.completed" class="mt-2" align="right">
              <button matStepperNext mat-flat-button (click)="enviarCompra()" style="background-color:#00364d;color: white; font-size: 300;">Encomendar</button>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </mat-card>
    </div>
    <div class="col-12" *ngIf="estadoEncomenda !== undefined && estadoEncomenda === 'Sucesso'">
      <app-sucesso [idEncomenda]="numeroEncomenda"></app-sucesso>
    </div>
  </div>
</div>
  
  <ng-template #carrinhoVazio>
    <div class="row d-flex justify-content-center" style="background: #FAFAFA; padding: 20px; border-bottom: solid 1px #F0F0F0">
      O Seu carrinho está Vazio
    </div>
  </ng-template>
  