<app-header></app-header>
<div class="container">
    <div class="row p-0 m-0">
        <!--<div class="col-2">
            <app-menu-produtos></app-menu-produtos>
        </div>
        <div class="col-12 col-lg-10">-->
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-rodape></app-rodape>
