<div class="container">
  
  <div class="secao">
    <h3 class="text-center">Cinema, viagens, diversão muito e mais!</h3>
    <h5 class="text-center">Conheça as melhores ofertas para você se divertir quando quiser:</h5>
  </div>

  <hr />
  <div *ngFor="let dPROMO of xPromocoes">
    <div class="row">
      <div class="col-12">
        <img [src]="dPROMO.imagens[0].url" />
      </div>

      <div class="col-12">
        <div class="row">
          <h4>{{dPROMO.titulo}}</h4>
        </div>

        <div class="row">
          <h5>{{dPROMO.descricao_oferta}}</h5>
        </div>

        <div class="row">
          <p>Anunciado por: <b>{{dPROMO.anunciate}}</b></p>
        </div>

        <div class="row">
          <p>{{dPROMO.valor | currency:'EUR':true}} </p>
        </div>

        <div class="row">
            <button class="btn btn-outline-success" [routerLink]="['/produto',dPROMO.id]">Ver Produto</button>
        </div>
      </div>
    </div>
    <hr />
  </div>



  <hr />

  <hr />

</div>