<div class="" style="background-color: #fafafa;">
  <div class="menu">
    <div class="container">
      <div class="row m-0 pt-2 pb-2 barraTopo" style="margin-left: 0px;">
        <!--LOGOTIPO-->
        <div class="col-6 col-lg-3" style="vertical-align: bottom;">
          <a href="#" class="navbar-brand">
            <img src="/assets/logo.png" routerLink="" class="img-fluid" />
          </a>
        </div> 
        <!--LOGOTIPO-->

        <!--AREA DE PESQUISA-->
        <div class="col-12 col-lg-5 order-2 order-lg-1">
          <div class="input-group mt-2">
            <input type="text" class="form-control" style="width: 80%;" #dadosPesquisa (keyup)="pesquisa(dadosPesquisa.value)"  placeholder="pesquisar...">
            <div class="input-group-prepend mt-0 pt-0">
                <button class="btn btn-outline-light my-0" [routerLink]="['/pesquisa',dadosPesquisa.value]" style="border-top-right-radius: 10px; border-bottom-right-radius: 10px;" type="submit"><i class="fa fa-search"></i></button>
            </div>
          </div> 
        </div>
        <!--AREA DE PESQUISA-->

        <!--AREA DE CLIENTE-->
        <div class="col-4 col-lg-4 p-0 order-1 order-lg-2" >
          <div class="row justify-content-end p-0 m-0">
              <div class="col-4 col-lg-3 d-flex justify-content-end" align="right">
                <a *ngIf="this.c_utilizadorService.get_utilizador.nome" [routerLink]="['/carrinho']"> <!--(mouseenter)="mouseEnter(event)" (mouseleave)="mouseLeave()" -->
                  <i class="material-icons mt-2" matTooltipClass='checkout-tooltip'  matTooltip="Efetuar o Checkout" data-placement="bottom" [matBadge]="this.c_WS.qttCarrinho" matBadgeColor="warn" [matBadgeHidden]="this.c_WS.qttCarrinho === 0" style="font-size: 30px; color: white;">shopping_cart</i>
                  <div *ngIf="isDisplay then areaCarrinho" ></div>
                </a>
              </div>
              <div *ngIf="this.c_utilizadorService.get_utilizador.nome === undefined then login else logado"></div>
          </div>        
        </div>
        <!--AREA DE CLIENTE-->
      </div>
    </div>
  </div>
  <!--MENU DESKTOP-->
  <div class="container mt-0 pt-0  d-none d-lg-block" style="background-color: #fafafa;">
      <nav class="main-nav d-flex justify-content-center" role="navigation">

        <!-- Mobile menu toggle button (hamburger/x icon) -->
        <input id="main-menu-state" type="checkbox" />
        <label class="main-menu-btn" for="main-menu-state">
          <i class="fas fa-bars"></i>
        </label>

        <!-- Sample menu definition -->
        <ul  id="main-menu" class="sm sm-blue">     
          <li *ngFor="let MenuPrincipal of this.xMenu"><a href="#" (click)="familias(MenuPrincipal.id)" >{{MenuPrincipal.menu}}</a>
            <ul>
              <li *ngFor="let MenuFamilia of this.xFamilia">
                <a href="#" (click)="subfamilias(MenuFamilia.id)">{{MenuFamilia.familia}}</a>
                <ul>
                  <li *ngFor="let MenuSubFamilia of this.xSubFamilia">
                    <a [routerLink]="['produtos', MenuFamilia.id, MenuSubFamilia.id]">{{MenuSubFamilia.subfamilia}}</a>
                  </li>
                </ul>  
              </li> 
            </ul>
          </li> 
         <!--<li><a [routerLink]="['novidades']">Novidades</a></li>-->
          
          <li><a [routerLink]="['contactos']">Contactos</a></li>
        </ul>
      </nav>
  </div>
  <!--MENU DESKTOP-->
  <!--MENU MOBILE-->
  <div class="container mt-0 pt-0 d-lg-none" style="background-color: #fafafa;">
    <div class="accordion d-lg-none" id="MENUPRINCIPAL">
      <div class="accordion-item" style="border-left:0px; border-right: 0px;" *ngFor="let menuPrincipal of xMenu">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" (click)="familias(menuPrincipal.id)" data-bs-toggle="collapse" [attr.data-bs-target]="'#itensMenu'+menuPrincipal.id">
            {{menuPrincipal.menu}}
          </button>
        </h2>
        <div  [id]="'itensMenu'+menuPrincipal.id" class="accordion-collapse collapse" data-bs-parent="#MENUPRINCIPAL">
          <div class="accordion-body">
            <div class="accordion"  [id]="'PAI'+menuPrincipal.id">
              <div class="accordion-item" style="border-left:0px; border-right: 0px;" *ngFor="let MFamilias of xFamilia">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" (click)="subfamilias(MFamilias.id)"  data-bs-toggle="collapse" [attr.data-bs-target]="'#bt'+MFamilias.id">{{MFamilias.familia}}</button>
                </h2>
                <div [id]="'bt'+MFamilias.id" class="accordion-collapse collapse" [attr.data-bs-parent]="'#PAI'+menuPrincipal.id">
                  <div class="accordion-item" style="border-left:0px; border-right: 0px;" *ngFor="let MSubfamilias of xSubFamilia">
                    <h2 class="accordion-header p-2">
                      <a [routerLink]="['produtos', MFamilias.id, MSubfamilias.id]" data-bs-toggle="collapse" [attr.data-bs-target]="'#itensMenu'+menuPrincipal.id"  class="m-5" style="color: black; font-size: 14px;">{{MSubfamilias.subfamilia}}</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--MENU MOBILE-->
</div>

<!--<app-breadcrumbs></app-breadcrumbs>-->
<!--
<div class="banner m-0 p-0">
  <img src="/assets/banner.png"/>
</div>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>-->



<!--Templates-->
      
<ng-template #loading>
  <div class="spinner-grow" role="status">
    <span >Loading...</span>
  </div>
</ng-template>

<ng-template #login >
  <div class="col-12 col-lg-9 d-flex justify-content-end mt-2" align="left">
    <a (click)="abrirLogin()" matTooltip="Login"  matTooltipClass='login-tooltip' data-placement="bottom">
        <i class="material-icons" style="font-size: 30px; color: whitesmoke;vertical-align:middle">account_circle</i>
        <span class="login-txt">Login</span>
    </a>
  </div>
</ng-template>
<ng-template #logado>
  <div class="col-8 col-lg-9 d-flex justify-content-end mt-2" style="z-index: 9999;" align="left">
    <div class="dropdown d-none d-lg-block" >
      <span class="dropdown-toggle" type="button" id="dropdownMenuButton" style="z-index: 9999;" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="material-icons"  style="font-size: 30px; color:white; vertical-align: middle;">account_box</i>
        <span class="login-txt">Bem Vindo {{this.c_utilizadorService.get_utilizador.nome }}</span>
      </span>
      <div class="dropdown-menu " style="background-color: #00364d; z-index: auto; position: absolute;" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" style="z-index: 99999;" [routerLink]="['/meusdados']">Meus Dados</a>
        <a (click)="logout()" class="dropdown-item" href="#">Log Out</a>
      </div>
    </div>
    <div class="dropdown  d-lg-none">
      <span class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="material-icons"  style="font-size: 30px; color:white; vertical-align: middle;">account_box</i>
      </span>
      <div class="dropdown-menu dropdown-menu-right" style="background-color: #00364d;" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" [routerLink]="['/meusdados']">Meus Dados</a>
        <a (click)="logout()" class="dropdown-item" href="#">Log Out</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #areaCarrinho>
  <div class="popup_carrinho">
    <div class="row" style="background-color: whitesmoke !important;"> 
      <div class="col-6 p-3" align="center"><i class="material-icons" style="font-size: 50px;">
          add_shopping_cart
          </i></div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 mt-3 subtitulos_cinzas" align="right">Sub-Total: {{c_WS.totalSIvaCarrinho | currency:'EUR'}}</div>
          <div class="col-12 mt-1 subtitulos_cinzas" align="right">Iva(23%): {{c_WS.totalIVA | currency:'EUR'}}</div>
          <div class="col-12 mt-1 subtitulos_cinzas" align="right">Total: {{c_WS.totalCIvaCarrinho | currency:'EUR'}}</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-1" style="background-color: #00364d !important;"> 
      <div class="col-12 m-1" align="center">
        <a  routerLink='/carrinho' ><button class="btn btn-light" style="border-radius: 0;">Ver Carrinho</button></a>&nbsp;
        <button routerLink='/checkout' class="btn btn-light" style="border-radius: 0;">Efetuar Compra</button>
      </div>
    </div>
  </div>
</ng-template>
<!--templates-->