<div class="container pt-3">
    <app-dadoscliente *ngIf="this.ecraConta == 1"></app-dadoscliente>
    <app-moradas *ngIf="this.ecraConta == 2"></app-moradas>
    <app-historico *ngIf="this.ecraConta == 3"></app-historico>
    <app-alterarpassword *ngIf="this.ecraConta == 4"></app-alterarpassword>
    <div class="row mt-4">
        <div class="col-12 col-lg-3 mt-1">
            <mat-card  class="linksMD" (click)="definirEcraConta(1)">
                <mat-card-content>
                    <div class="row justify-content-center">
                        <div class="col-12 ">
                            <i class="fas fa-user-edit fa-2x" style=" vertical-align: middle;"></i>
                            <span class="ml-2 h5">Meus Dados</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12 col-lg-3 mt-1">
            <mat-card class="linksMD" (click)="definirEcraConta(2)">
                <mat-card-content>
                    <div class="row">
                        <div class="col-12">
                            <i class="fas fa-map-marker-alt fa-2x" style=" vertical-align: middle;"></i>
                            <span class="ml-2 h5">Moradas de Descarga</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12 col-lg-3 mt-1">
            <mat-card  class="linksMD" (click)="definirEcraConta(3)">
                <mat-card-content>
                    <div class="row">
                        <div class="col-12 pr-0">
                            <i class="fas fa-shopping-basket fa-2x" style=" vertical-align: middle;"></i>
                            <span class="ml-2 h5">Histórico Encomendas</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12 col-lg-3 mt-1">
            <mat-card  class="linksMD" (click)="definirEcraConta(4)">
                <mat-card-content>
                    <div class="row justify-content-center">
                        <div class="col-12" style="vertical-align: text-top;">
                            <i class="fas fa-key fa-2x" style=" vertical-align: middle;"></i>
                            <span class="ml-2 h5">Alterar Password</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>