<!--<app-historico *ngIf="this.voltar===1"></app-historico>-->
<div class="container" *ngIf="this.voltar===0">
    <div class="row mt-2">
      <mat-card class="col-12">
        <div class="row">
            <div class="col-8">
                <h5>Caro(a) {{this.us.get_utilizador.nome}}</h5>
                <p>Obrigado por comprar na nossa loja online. </p>
            </div>
            <div class="col-4" align="right">
                <button class="" mat-raised-button color="primary" (click)="voltarEncomendas()">Voltar</button>
            </div>
        </div>
      </mat-card>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 pr-1">
        <mat-card class="p-0" >
          <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Dados da Encomenda</div>
            <mat-card-content class="p-3">
              <div class="row">
                <div class="col-6">Encomenda Nº {{this.idEncomenda}}</div>
              </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!--MORADAS--> 
    <div class="row mt-2"> 
      <div class="col-12 col-lg-6 p-0 pr-1">
        <mat-card class="p-0" >
          <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Informação de Faturação</div>
            <mat-card-content class="p-3">
              <div class="form-row p-0">
                <mat-form-field class="col-12 p-0">
                  <mat-label>Morada</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.morada}}">
                </mat-form-field>
              </div>
              <div class="form-row p-0">
                <mat-form-field class="col-6 p-0">
                  <mat-label>Localidade</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.localidade}}">
                </mat-form-field>
                <mat-form-field class="col-6 p-0">
                  <mat-label>Código Postal</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.codpostal}}">
                </mat-form-field>
              </div>
          </mat-card-content>
        </mat-card>
      </div>
  
      <div class="col-12 col-lg-6 p-0 pl-1">
        <mat-card class="p-0" >
          <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Morada Envio</div>
            <mat-card-content class="p-3">
              <div class="form-row p-0">
                <mat-form-field class="col-12 p-0">
                  <mat-label>Morada</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.morada_envio}}">
                </mat-form-field>
              </div>
              <div class="form-row p-0">
                <mat-form-field class="col-6 p-0">
                  <mat-label>Localidade</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.localidade_envio}}">
                </mat-form-field>
                <mat-form-field class="col-6 p-0">
                  <mat-label>Código Postal</mat-label>
                  <input matInput readonly value="{{this.dEncomenda?.codpostal_envio}}">
                </mat-form-field>
              </div>
              
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!--MORADAS-->
    
    <!--METODO DE PAGAMENTO / ENVIO-->
    
    <div class="row mt-2">
      <div class="col-12 col-lg-6 p-0 pr-1">
        <mat-card class="p-0" >
          <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Informação de Pagamento</div>
          <mat-card-content class="p-3">
            <div class="form-row p-0">
              <mat-form-field class="col-12 p-0">
                <mat-label>Metodo Pagamento</mat-label>
                <input matInput readonly value="{{this.dEncomenda?.metodo_pagamento}}">
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-12 col-lg-6 p-0 pr-1">
        <mat-card class="p-0" >
          <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Informação de Envio</div>
          <mat-card-content class="p-3">
            <div class="form-row p-0">
              <mat-form-field class="col-12 p-0">
                <mat-label>Metodo Envio</mat-label>
                <input matInput readonly value="{{this.dEncomenda?.metodo_envio}}">
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!--METODO PAGAMENTO-->
    <!--PRODUTOS-->
    <div class="row mt-2">
      <div class="col-12 p-0 pr-1">
        <mat-card class="p-0" >
          <div class="p-2" style="border-radius:5px 5px 0px 0px">
            <div class="row mb-0 pb-0">
              <div class="col-1"><b></b></div>
              <div class="col-5"><b>Produto</b></div>
              <div class="col-2"><b>Qtd.</b></div>
              <div class="col-2"><b>Preço</b></div>
              <div class="col-2"><b>Total</b></div>
            </div>
          </div>
          <hr class="m-0">
          <mat-card-content class="p-3">
            <div *ngFor = "let dCH of this.dEncomendaLinhas">
              <div class="row">
                <div class="col-1" style="vertical-align: middle;"><img src="https://imagens.asdepecas.pt/{{dCH.imagem}}" class="img-fluid"></div>
                <div class="col-5" style="vertical-align: middle;"><a href="" style="color: black;"  [routerLink]="['/produto_info',dCH.id_produto]">{{dCH.produto}}</a></div>
                <div class="col-2">{{dCH.qtt}}</div>
                <div class="col-2">{{dCH.preco_civa | currency:'EUR':true }}</div>
                <div class="col-2">{{dCH.total | currency:'EUR':true }}</div>
              </div>
              <hr class="m-0">
            </div>
          </mat-card-content>
          <hr>
           <mat-card-content class="p-3">
             <div class="row">
               <div class="col-12" align="right">
                  <strong>Total s/IVA:</strong> {{this.dEncomenda?.total_siva | currency:'EUR':true}}
               </div>
               <div class="col-12" align="right">
                  <strong>Portes: </strong> {{this.dEncomenda?.portes | currency:'EUR':true}}
               </div>
               <div class="col-12 mt-2" align="right">
                  <strong>Total:</strong> {{this.dEncomenda?.total | currency:'EUR':true}}
               </div>
             </div>
           </mat-card-content>
        </mat-card>
      </div>
    </div>
    