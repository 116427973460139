import { Component, OnInit, Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {Router} from '@angular/router'
import {webService} from '../tools/webservice.service'
import { dadosMenu } from '../modelos/Menus.model';



@Injectable()

@Component({
  selector: 'app-menu-produtos',
  templateUrl: './menu-produtos.component.html',
  styleUrls: ['./menu-produtos.component.css']
})

export class MenuProdutosComponent implements OnInit  {
  public xMenus:dadosMenu[] 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  

  constructor(private breakpointObserver: BreakpointObserver,private c_WS:webService, private c_Router:Router) {
    this.xMenus = []
  }

  ngOnInit() {
    this.c_WS.loadMenus().subscribe(dados=>{
      this.xMenus = dados        
    })
  }
  
  selecionarFamilia(dFamilia,dSubfamilia){
    this.c_Router.navigate(['/produtos',dFamilia.id,dSubfamilia.id])
    this.c_WS.loadProdutos(dFamilia.id,dSubfamilia.id).subscribe(dados=>{   
      return dados
    })
  }
}
