import { Component, OnInit } from '@angular/core';
import { webService } from '../tools/webservice.service';
import { dadosProdutos } from '../modelos/produtos.model'
import { utilizadorService } from '../tools/utilizador.service';
import { CookieService } from 'ngx-cookie-service'
 


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public xDestaques: dadosProdutos[]
  public erro: any
  
  constructor(private c_WS: webService, private cookie:CookieService,private xUser:utilizadorService) { 
    
  }

  ngOnInit() {
    this.c_WS.loadProdutos('destaque','x').subscribe((dados:dadosProdutos[])=>{ 
      this.xDestaques = dados
    },
    (deuErro:any) => {
      console.log("Erro Geral",deuErro)
    })
  } 

  addCarrinho(produto:dadosProdutos){
    if(this.cookie.get('protecao_id').length>0){
      this.c_WS.addCarrinho(produto,1,this.cookie.get('protecao_id')).subscribe((addRes)=>{
        if(addRes===1){
          this.c_WS.executaSnackBar("Produto adicionado com sucesso ao seu carrinho","bg-primary");
          this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta')  
        }
      })
    } else {
      this.c_WS.executaSnackBar("Tem de fazer login","bg-danger");
    }
  }


  

}
