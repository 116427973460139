import { Component, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute,Params} from '@angular/router';
import { webService } from '../tools/webservice.service';
import { dadosProdutos } from '../modelos/produtos.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Lightbox, LightboxModule } from 'ngx-lightbox';

@Component({
  selector: 'app-produto-info',
  templateUrl: './produto-info.component.html',
  styleUrls: ['./produto-info.component.css']
})
export class ProdutoInfoComponent implements OnInit {
  public idProduto: any;
  public DadosProduto: dadosProdutos;
  public marcasAtribuidas: string[];
  public estadoProduto: string;
  public corEstado: string;
  public Estado: boolean;
  public mostrarLoading:boolean = true;
  public totalQtt:number = 0

  public _albums = [];
   

  xFAddCarrinho:FormGroup = new FormGroup({
    'xQtt': new FormControl(this.totalQtt,[Validators.required, Validators.min(1)])
  });
  

  constructor(public c_route:ActivatedRoute, public c_WS:webService,private cookie:CookieService,private _lightbox: Lightbox) { 
    this.DadosProduto = new dadosProdutos()

    
  }

  ngOnInit(): void {
    this.c_route.params.subscribe((variavel: Params)=>{
      this.idProduto = variavel.idProduto
      this.c_WS.loadProdutoInfo(this.idProduto).subscribe((dados:dadosProdutos)=>{ 
        
        this.DadosProduto = dados
        for (let i = 1; i <= 3; i++) {
          const link = ''
          const xImagem = this['DadosProduto']['imagem'+i]
          const src = 'https://imagens.asdepecas.pt/'+xImagem;
          
          const caption = this['DadosProduto']['produto'];
          const thumb = 'https://imagens.asdepecas.pt/'+xImagem;
          
          const album = {
            src: src.trim(),
            caption: caption,
            thumb: thumb
          };
          if(xImagem!=null && xImagem.trim()){
            this._albums.push(album);
          }
        }

        this.marcasAtribuidas = this.DadosProduto.marca.split(';'); 
        this.estadoProduto = dados.estado.trim()
        if(this.estadoProduto=='Disponível para entrega') {
          this.corEstado = "background-color: #7ad672; color:darkgreen;  width:100%;"
          this.Estado = false
        } else if(this.estadoProduto=='Apenas sob encomenda') {
          this.corEstado = "background-color: #ff6666; color:darkred;  width:100%;"
          this.Estado = true
        } else {
          this.corEstado = "background-color: #ffb785; color:#ff6a00;  width:100%;"
          this.Estado = false
        }
      },
      (deuErro:any) => {
        this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
      },
      ()=>{ 
        this.mostrarLoading = false
      })
    }); 

  } 

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  

  addQtt() {
    this.totalQtt = this.totalQtt + 1
    return this.totalQtt
  }
  remQtt() {
    if(this.totalQtt>0) {
      this.totalQtt = this.totalQtt - 1
      return this.totalQtt
    } else {
      return this.totalQtt
    }
  }
  addCarrinho(produto:dadosProdutos){
    console.log("entrou"+this.totalQtt)
    if(this.cookie.get('protecao_id').length>0){
      this.c_WS.addCarrinho(produto,this.totalQtt,this.cookie.get('protecao_id')).subscribe((addRes)=>{
        console.log(addRes)
        if(addRes===1){
          this.c_WS.executaSnackBar("Produto adicionado com sucesso ao seu carrinho","bg-primary");
          this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta') 
          this.totalQtt = 0 
        }
      })
    } else {
      this.c_WS.executaSnackBar("Tem de realizar o Login","bg-danger");
    }
  }
}
