import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { RouterModule} from '@angular/router'
//import { FormsModule } from '@angular/forms'
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import "@angular/compiler"
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

/**Importacao de Links */
import {LINKS} from './app.links'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { RodapeComponent } from './rodape/rodape.component';
import { ContactosComponent } from './contactos/contactos.component';
import { PromocoesComponent } from './promocoes/promocoes.component';
import { ProdutoInfoComponent } from './produto-info/produto-info.component';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { SucessoComponent } from './sucesso/sucesso.component';
import { LoginComponent } from './header/login/login.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatBadgeModule }  from '@angular/material/badge'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatTabsModule }  from '@angular/material/tabs'
import { MatCardModule } from '@angular/material/card'
import { MatStepperModule } from '@angular/material/stepper'
import { MatRadioModule } from '@angular/material/radio'
import { MatProgressBarModule} from '@angular/material/progress-bar'
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatDialogModule} from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuProdutosComponent } from './menu-produtos/menu-produtos.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatCheckboxModule} from '@angular/material/checkbox'
import {MatChipsModule} from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { NgxMaskModule } from 'ngx-mask'
import { BreadcrumbModule } from 'angular-crumbs'
import { LightboxModule } from 'ngx-lightbox';




/***SERVICES */

import { autenticado } from  './tools/autenticado.service'
import { utilizadorService } from  './tools/utilizador.service'
import { CookieService } from 'ngx-cookie-service';
import {  webService } from './tools/webservice.service';
import { ProdutosComponent } from './produtos/produtos.component';
import { CheckoutComponent } from './carrinho/checkout/checkout.component';
import { CarrinhoComponent } from './carrinho/carrinho.component'

import localeFr from '@angular/common/locales/fr';
import { ContaComponent } from './conta/conta.component';
import { DadosclienteComponent } from './conta/dadoscliente/dadoscliente.component';
import { AlterarpasswordComponent } from './conta/alterarpassword/alterarpassword.component';
import { MoradasComponent } from './conta/moradas/moradas.component';
import { BreadcrumbsComponent } from './header/breadcrumbs/breadcrumbs.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { TermosCondicoesComponent } from './termos-condicoes/termos-condicoes.component';
import { HistoricoComponent } from './conta/historico/historico.component';
import { EncomendasComponent } from './conta/historico/encomendas/encomendas.component';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent, 
    RodapeComponent,
    ContactosComponent,
    PromocoesComponent,
    SucessoComponent,
    LoginComponent,
    MenuProdutosComponent,
    ProdutosComponent,
    CheckoutComponent,
    CarrinhoComponent,
    ContaComponent,
    DadosclienteComponent,
    AlterarpasswordComponent,
    MoradasComponent,
    BreadcrumbsComponent,
    ProdutoInfoComponent,
    PoliticaPrivacidadeComponent,
    TermosCondicoesComponent,
    HistoricoComponent,
    EncomendasComponent
  ],
  entryComponents: [LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(LINKS, { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule, 
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    FlexLayoutModule,
    LayoutModule,
    NgxMaskModule.forRoot(),
    BreadcrumbModule,
    LightboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    
  ],
  exports:[MatToolbarModule,
    MatButtonModule,
    MatIconModule],
  providers: [
            { provide: LOCALE_ID, useValue: 'fr-FR' },
            {provide: LocationStrategy, useClass: HashLocationStrategy},
            autenticado,
            utilizadorService,
            CookieService, 
            webService],
  bootstrap: [AppComponent]
})
export class AppModule { }
