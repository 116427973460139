<div class="container-fluid footer mt-4" style="border-top:1px solid #ededed;">
  <div class="row" style="background-color: #fafafa; background-attachment: scroll">
    <div class="container">
      <div class="row p-4">
        <div class="col-4">
          <span class="rodapetitulo">INFORMAÇÕES</span>
          <hr>
            <a href="" class="linkPreto m-0 p-0 d-flex align-content-center">
              <i class="material-icons rodapeIcons">home</i> A Empresa
            </a>
            <a href="" routerLink="/termoscondicoes" class="linkPreto mt-2 d-flex align-content-center">
              <i class="material-icons rodapeIcons">description</i> Termos e Condições
            </a>
            <a href="" routerLink="/politicaprivacidade" class="linkPreto mt-2 d-flex align-content-center">
              <i class="material-icons rodapeIcons">vpn_lock</i> Política de Privacidade e proteção de dados
            </a>
        </div>
        <div class="col-4">
          <span class="rodapetitulo">ASSISTÊNCIA AO CLIENTE</span>
          <hr>
            <a href="https://www.livroreclamacoes.pt/" target="_blank" class="linkPreto m-0 p-0 d-flex align-content-center">
              <i class="material-icons rodapeIcons">menu_book</i> Livro de Reclamações
            </a>
            <a href="" routerLink="/contactos" class="linkPreto mt-2 d-flex align-content-center">
              <i class="material-icons rodapeIcons">phone</i> Contactos
            </a>
        </div>
        <div class="col-4">
          <span class="rodapetitulo">MINHA CONTA</span>
          <hr>
            <a href="" class="linkPreto m-0 p-0 d-flex align-content-center">
              <i class="material-icons rodapeIcons" routerLink="/meusdados">person</i> Conta de Cliente
            </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="background-color: #fafafa; background-attachment: scroll;border-top:1px solid #ededed;">
    <div class="container">
      <div class="row">
        <div class="col-12" style="color: #ccc" align="right">Às de Peças © Copyright 2020. All Rights Reserved. Powered by AJTEC.</div>
      </div>
    </div>
  </div>
</div>
