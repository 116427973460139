<div class="container">
  <div class="row mt-2">
    <mat-card class="col-12">
      <h5>Caro(a) {{this.c_utilizadorService.get_utilizador.nome}}</h5>
      <p>Obrigado por comprar na nossa loja online.</p>
     
    </mat-card>
  </div>
  <div class="row mt-2">
    <div class="col-12 p-0 pr-1">
      <mat-card class="p-0" >
        <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Dados da Encomenda</div>
          <mat-card-content class="p-3">
            <div class="row">
              <div class="col-6">Encomenda Nº {{this.idEncomenda}}</div>
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!--MORADAS-->
  <div class="row mt-2">
    <!--<div class="col-12 col-lg-6 p-0 pr-1">
      <mat-card class="p-0" >
        <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Informação de Faturação</div>
          <mat-card-content class="p-3">
            <div class="form-row p-0">
              <mat-form-field class="col-12 p-0">
                <mat-label>Morada</mat-label>
                <input matInput readonly value="{{this.DadosMFT?.morada}}">
              </mat-form-field>
            </div>
            <div class="form-row p-0">
              <mat-form-field class="col-6 p-0">
                <mat-label>Localidade</mat-label>
                <input matInput readonly value="{{this.DadosMFT?.localidade}}">
              </mat-form-field>
              <mat-form-field class="col-6 p-0">
                <mat-label>Código Postal</mat-label>
                <input matInput readonly value="{{this.DadosMFT?.codpostal}}">
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="col-6 p-0">
                <mat-label>Distrito</mat-label>
                <input matInput readonly value="{{this.DadosMFT?.distrito}}">
              </mat-form-field>
              <mat-form-field  class="col-6 p-0">
                <mat-label>País</mat-label>
                <input matInput readonly value="{{this.DadosMFT?.pais}}">
              </mat-form-field>
            </div>
        </mat-card-content>
      </mat-card>
    </div>-->
    <div class="col-12 col-lg-12 p-0 pl-1">
      <mat-card class="p-0" >
        <div class="p-2 subtitulos_azuis" style="border-radius:5px 5px 0px 0px">Morada Envio</div>
          <mat-card-content class="p-3">
            <div class="form-row p-0">
              <mat-form-field class="col-12 p-0">
                <mat-label>Morada</mat-label>
                <input matInput readonly value="{{this.DadosME?.morada}}">
              </mat-form-field>
            </div>
            <div class="form-row p-0">
              <mat-form-field class="col-6 p-0">
                <mat-label>Localidade</mat-label>
                <input matInput readonly value="{{this.DadosME?.localidade}}">
              </mat-form-field>
              <mat-form-field class="col-6 p-0">
                <input matInput readonly value="{{this.DadosME?.codpostal}}">
              </mat-form-field>
            </div>
            <!--<div class="form-row">
              <mat-form-field class="col-6 p-0">
                <mat-label>Distrito</mat-label>
                <input matInput readonly value="{{this.DadosME?.distrito}}">
              </mat-form-field>
              <mat-form-field  class="col-6 p-0">
                <mat-label>Paí<s></s></mat-label>
                <input matInput readonly value="{{this.DadosME?.pais}}">
              </mat-form-field>
            </div>-->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!--MORADAS-->
  <!--PRODUTOS-->
  <div class="row mt-2">
    <div class="col-12 p-0 pr-1">
      <mat-card class="p-0" >
        <div class="p-2" style="border-radius:5px 5px 0px 0px">
          <div class="row mb-0 pb-0">
            <div class="col-2"><b></b></div>
            <div class="col-5"><b>Produto</b></div>
            <div class="col-2"><b>Qtd.</b></div>
            <div class="col-2"><b>Preço</b></div>
            <div class="col-2"><b>Total</b></div>
          </div>
        </div>
        <hr class="m-0">
        <mat-card-content class="p-3">
          <div *ngFor = "let dCH of this.listaCarrinhoHistorico">
            <div class="row">
              <div class="col-1" style="vertical-align: middle;"><img src="https://imagens.asdepecas.pt/{{dCH.referencia}}.jpg" class="img-fluid"></div>
              <div class="col-5" style="vertical-align: middle;">{{dCH.produto}}</div>
              <div class="col-2">{{dCH.qtt}}</div>
              <div class="col-2">{{dCH.preco_civa | currency:'EUR':true }}</div>
              <div class="col-2">{{dCH.total_civa | currency:'EUR':true }}</div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 p-0 pr-1">
      <mat-card class="p-2" align='right'>
        <button mat-flat-button routerLink="/" style="background-color:#00364d;color: white; font-size: 300;">Continuar</button>
      </mat-card>
    </div>
  </div>