import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVIDORPHP } from '../tools/app.api'
import { dadosLogin } from '../modelos/cliente.model'
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/Rx';


import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { CookieService } from 'ngx-cookie-service'
import { webService } from './webservice.service';
import { checkoutMorada } from '../modelos/checkoutModel';
 

@Injectable()

export class utilizadorService {

    public protecao_id: string
    public userName : string
    public get_utilizador : dadosLogin 
    public userNif: string;
    public DME: checkoutMorada;
    public DMF: checkoutMorada;
    public idEncomendaConsultar:number;
    
    constructor(private http:HttpClient, private clink:Router, private c_WS:webService, private cookie:CookieService, private _snackBar: MatSnackBar){
        this.get_utilizador = new dadosLogin()
    }
    

    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8    '
       })
    };
   

    funcaoLogin(xuser,xpassword):Observable<dadosLogin>{
        let cabecalhos:Headers = new Headers()
        
        /*let body = new FormData();
        body.append('user', 'xicoeusebio@gmail.com');
        body.append('password', 'xyz');*/
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{user:xuser,password:xpassword},this.httpOptions,)
            .map((resposta: any)=>{  
                if(resposta===null){
                    this.openSnackBar("Utilizador ou Password errados",4000,"red-snackbar")
                } else {
                    this.get_utilizador = resposta
                    this.get_utilizador.responsavel = resposta.responsavel_encomenda
                    this.protecao_id = resposta.validador
                    this.cookie.set('protecao_id',this.protecao_id)
                    this.c_WS.estadoCarrinho(this.protecao_id,'consulta')
                    this.userName = resposta.nome
                    this.userNif = resposta.nif
                }             
                return resposta
            })
    }

    //Regista Autenticacao
    autenticado():boolean {
        if(this.protecao_id === undefined)
        {
            this.clink.navigate(['/'])   
        }
        return this.protecao_id !== undefined
    }

    //Carrega Dados do Login
    Logado():any{   
        
        if(this.protecao_id === undefined && this.cookie.get('protecao_id')!=='')
        {
            this.protecao_id = this.cookie.get('protecao_id')
        }
        if(this.protecao_id !== undefined) {
            return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{  id : this.protecao_id},this.httpOptions)
                .subscribe((resposta:any)=>{ 
                    this.get_utilizador.validador = resposta.validador
                    this.get_utilizador.nome = resposta.nome
                    this.get_utilizador.email = resposta.email
                    this.get_utilizador.nphc = resposta.nphc
                    this.get_utilizador.nif = resposta.nif
                    this.get_utilizador.localidade = resposta.localidade
                    this.get_utilizador.codpostal = resposta.codpostal
                    this.get_utilizador.morada = resposta.morada
                    this.get_utilizador.telemovel = resposta.telemovel
                    this.get_utilizador.telefone = resposta.telefone
                    this.get_utilizador.responsavel = resposta.responsavel_encomenda
                    this.get_utilizador.id = resposta.id
                    this.c_WS.estadoCarrinho(this.protecao_id,'consulta')
                    
                    return true
            })

        } else {
            return false
        }
    }

    //Utilizador perdeu a password
    recuperarPW(xEmail){
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{acao:'recpw',xEmail:xEmail},this.httpOptions,)
        .map((resposta: any)=>{  
            if(resposta!='1'){
                this.openSnackBar("Ocorreu um erro. Tente novamente mais tarde.",4000,"red-snackbar")
            } else {
                return resposta
            }
        })
    }

    //Alterar Password
    funcaoAlterarPassword(xUser,xPassword):Observable<dadosLogin>{
        let cabecalhos:Headers = new Headers()
        
        /*let body = new FormData();
        body.append('user', 'xicoeusebio@gmail.com');
        body.append('password', 'xyz');*/
        console.log(this.get_utilizador)
        console.log("UserID:"+xUser)
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{user:xUser,password:xPassword,acao:'apw'},this.httpOptions,)
                .map((resposta: dadosLogin)=>{  
                    if(resposta===null){
                        this.openSnackBar("Erro 02: Ocorreu um erro ao alterar a sua Password. Tente novamente ou contacte o Administrador de sistema",4000,"red-snackbar")
                    } else {
                        this.openSnackBar("Password alterada com sucesso.",4000,"green-snackbar")
                    }             
                    
                    return resposta
                }) 
    }

    //LogOut
    logout():void{
        this.cookie.delete('protecao_id')
        this.get_utilizador.nome = undefined
    }


    /** MORADAS ****/
    //Carrega Todas as Moradas
    mostraMoradas(validador:string,morada:string):Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{
            validador:validador,
            morada:morada,
            acao:'mostrarmoradas'
        },this.httpOptions,)
        .map((dadosMoradas: checkoutMorada[])=>{ 
           return dadosMoradas
        })
    }


    //Carrega Morada de Envio
    mostraMoradaEnvio(idEncomenda:string):Observable<checkoutMorada>{
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{idEncomenda:idEncomenda,acao:'carregamoradaenvio'},this.httpOptions,)
            .map((resposta: checkoutMorada)=>{  
                this.DME = resposta        
                return resposta
            })
    }

    //Adiciona Morada
    f_adicionarMorada(morada,codpostal,localidade,pais):Observable<string>{
        let cabecalhos:Headers = new Headers()
        let nphc = this.get_utilizador.nphc
        let nome = this.get_utilizador.nome
        let email = this.get_utilizador.email
        let acao = "morada"
        
        /*let body = new FormData();
        body.append('user', 'xicoeusebio@gmail.com');
        body.append('password', 'xyz');*/
        return this.http.post(`${SERVIDORPHP}/webserver/enviarEmail.php`,{nphc:nphc,nome:nome,morada:morada,codpostal:codpostal,localidade:localidade,pais:pais,acao:acao},this.httpOptions,)
                .map((resposta: string)=>{         
                    return resposta
                }) 
    }
    //Predefinir Morada
    f_predefinirMorada(idMorada):Observable<string>{
        let nphc = this.get_utilizador.nphc
        let acao = "premorada"
        let morada = idMorada

        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{nphc:nphc,morada:morada,acao:acao},this.httpOptions,)
                .map((resposta: string)=>{         
                    return resposta
                }) 
    }

    /** MORADAS ****/

    /** Historico */
    loadEncomendas():Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/verUser.php`,{validador:this.protecao_id,accao:'mostrarencomendas'},this.httpOptions,)
            .map((resposta: any[])=>{ 
            return resposta
        }) 
    }
    /** Historico */
    durationInSeconds = 5;

    openSnackBar(mensagem,duracao,cor) {
        this._snackBar.open(mensagem,'',{duration:duracao,
        panelClass: [cor]})
    }
    
}