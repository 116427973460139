<div class="container m-0 p-0">
  <div class="row" *ngIf="mostrarLoading" >
    <div class="col-12 Carregar" align="center">
      <img src="/assets/loading.gif">
      <h5 >A Carregar</h5>
    </div>
  </div>
  <div class="row" *ngIf="!mostrarLoading">
    <div class="col-12 col-lg-3 p-0 pr-2">
      <div class="row p-0 m-0">
        <div class="col-12 mt-3 m-0 p-0">
          <mat-card >
            <mat-card-content>
              <h5>Marcas</h5>
              <div class="row p-2">
               
                  <div class="col-6 col-lg-12 mt-3"  *ngFor="let dFiltros of this.xLFiltros">
                    <!--<mat-checkbox id="{{dFiltros}}" (change)="filtrarPorMarca($event,dFiltros)">{{dFiltros}}</mat-checkbox>-->
                    <input type="checkbox" (click)="filtrarPorMarca($event,dFiltros)"> {{dFiltros}}
                  </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9 p-0">
      <div class="row">
        <div class="col-12 col-lg-4 mt-3" *ngFor="let dProdutos of this.xLProdutos">
          <div class="card">
            <img class="card-img-top" src="https://imagens.asdepecas.pt/{{dProdutos.referencia}}.jpg" class="img-fluid" style="max-height: 250px;">
            <div class="card-block p-2">
              <div class="card-title produto_nome" style="font-size: small; height: 50px;">{{dProdutos.produto}}</div> 
              <p class="preco" align='right'>{{dProdutos.preco_civa | currency:'EUR':true}}</p>
            </div>
            <div class="card-footer p-1">
              <div class="row d-flex justify-content-center">
                <div class="col-6" align="right">
                  <button class="btn btn-outline-primary"  [routerLink]="['/produto_info',dProdutos.id]"  style="width:100%">
                    <i class="fas fa-plus-circle"></i> Info 
                  </button>
                </div>
                <div class="col-6">
                  <button class="btn btn-primary" style="width:100%" (click)="addCarrinho(dProdutos)">
                    <i class="fas fa-cart-plus"></i> Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 