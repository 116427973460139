
<div class="row" *ngIf="mostrarLoading" >
  <div class="col-12 Carregar" align="center">
    <img src="/assets/loading.gif">
    <h5 >A Carregar</h5>
  </div>
</div>
<div class="row" *ngIf="!mostrarLoading">
  <div class="col-12 col-lg-12">
    <!--<mat-card style="border-radius:0px;">
      <mat-card-content>-->
         <div class="row">
          <div class="col-12 col-lg-5">
            <div class="row">
              <div class="col-12">
                <img src="https://imagens.asdepecas.pt/{{DadosProduto.imagem}}" style="border:1px solid ghostwhite  " class="img-fluid">
              </div>
              <div class="col-4 mt-1" *ngFor="let image of _albums; let i=index">
                <img [src]="image.thumb" (click)="open(i)" class="img-fluid" style="border:1px solid ghostwhite  "/>
              </div>
            </div>
            
          </div>
          <div class="col-12 col-lg-7" align='left'>
            <div class="row justify-content-center">
              <div class="col-12">
                <mat-card-title><h5>{{DadosProduto.produto}}</h5></mat-card-title>
                <mat-card-subtitle>Referência: {{DadosProduto.referencia}}</mat-card-subtitle>
              </div>
              <hr>
              <div class="col-12">
                <strong>Marcas: </strong>
                <span *ngFor="let Marcas of this.marcasAtribuidas">{{Marcas}}&nbsp;</span>
              </div>
              <hr> 
              <div class="col-12">{{DadosProduto.descricao_site}}</div>
              <hr style="width:100%;" class="mb-0"> 
              <div class="col-12 col-lg-5 mt-2 mb-2">             
                <span class="preco">{{DadosProduto.preco_civa | currency:'EUR':'symbol'}}</span>&nbsp;<small><strong>Inc.IVA</strong></small>
              </div>
              <div class="col-12 col-lg-5 mt-3 mb-3">
                <mat-chip-list >
                  <mat-chip [style]="this.corEstado" selected>{{estadoProduto}}</mat-chip>
                </mat-chip-list>
              </div>
              <hr style="width:100%;" > 
              <div class="col-12">
                <form [formGroup]="xFAddCarrinho" [hidden]="this.Estado"><!-- novalidate (ngSubmit)="adicionarMorada()">-->
                  <div class="row justify-content-lg-start">
                    <div class="col-8 col-lg-4 mt-1 p-0" style="display: inline;" >
                      <div class="input-group">
                        <span class="input-group-btn">
                            <button type="button" (click)="remQtt()" style="border: 1px solid gainsboro;color: #00364d; border-radius: 5px 0px 0px 5px;" class="btn btn-light">
                              <span class="fas fa-minus"></span>
                            </button>
                        </span>
                        <input type="text" style="text-align: center;" class="form-control" formControlName="xQtt" value="{{this.totalQtt}}" min="1">
                        <span class="input-group-btn">
                            <button type="button" (click)="addQtt()" style="border: 1px solid gainsboro;color: #00364d; border-radius: 0px 5px 5px 0px;" class="btn btn-light">
                                <span class="fas fa-plus"></span>
                            </button>
                        </span>
                        <mat-card-subtitle class="mt-2">&nbsp;Unidades</mat-card-subtitle>
                      </div>
                    </div>
                    <div class="col-4 col-lg-5">
                      <!--BT Desktop-->
                      <button mat-flat-button color="primary" (click)="addCarrinho(DadosProduto)" class="d-none d-lg-block bg-primary">
                        <span class="material-icons">
                          add_shopping_cart
                        </span>Adicionar ao Carrinho
                      </button>
                      <!--BT Desktop-->
                      <!--BT Mobile-->
                      <button mat-flat-button  (click)="addCarrinho(DadosProduto)" class="d-lg-none bg-primary">
                        <span class="material-icons">
                          add_shopping_cart
                        </span> 
                      </button>
                      <!--BT Mobile-->
                    </div>
                  </div>
                </form>
              </div> 
            </div>
          </div>
        </div>
      <!--</mat-card-content> 
    </mat-card>-->
  </div>
</div>