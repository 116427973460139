import {Routes} from '@angular/router'
import {HomeComponent} from './home/home.component'
import {ContactosComponent} from './contactos/contactos.component'
import {PromocoesComponent} from './promocoes/promocoes.component'
import { LoginComponent } from './header/login/login.component'
import { autenticado } from './tools/autenticado.service'
import { ProdutosComponent } from './produtos/produtos.component'
import { ProdutoInfoComponent } from './produto-info/produto-info.component'
import { CheckoutComponent } from './carrinho/checkout/checkout.component'
import { CarrinhoComponent } from './carrinho/carrinho.component' 
import { SucessoComponent } from './sucesso/sucesso.component'
import { ContaComponent } from './conta/conta.component'
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component'
import { TermosCondicoesComponent } from './termos-condicoes/termos-condicoes.component'

export const LINKS: Routes = [
    {path: '',component: HomeComponent,
        data: {
            breadcrumb: 'Home',
        }
    },
    {path: 'login',component:LoginComponent,
        data: {
            breadcrumb: 'Login',
        }
    },
    {path: 'contactos', component:ContactosComponent,
        data: {
            breadcrumb: 'Contactos',
        }
    } ,
    {path: 'promocoes',component: PromocoesComponent,canActivate:[autenticado],
        data: {
            breadcrumb: 'Promoções',
        }
    },
    {path: 'produtos/:familia/:subfamilia',component: ProdutosComponent,
        data: {
            breadcrumb: 'Produtos',
        }
    },
    {path: 'pesquisa/:dpesquisa',component: ProdutosComponent,
        data: {
            breadcrumb: 'Pesquisa',
        }
    },
    {path: 'produto_info/:idProduto',component: ProdutoInfoComponent,
        data: {
            breadcrumb: 'Produtos',
        }
    },
    {path: 'carrinho', component:CarrinhoComponent, canActivate:[autenticado],
        data: {
            breadcrumb: 'Carrinho de Compras',
        }
    },
    {path: 'checkout', component:CheckoutComponent, canActivate:[autenticado],
        data: {
            breadcrumb: 'Encomendar',
        }
    },
    {path: 'sucesso', component:SucessoComponent, canActivate:[autenticado],
        data: {
            breadcrumb: 'Sucesso',
        }
    },
    {path: 'meusdados', component:ContaComponent, canActivate:[autenticado],
        data: {
            breadcrumb: 'Dados Pessoais',
        }
    },
    {path: 'politicaprivacidade', component:PoliticaPrivacidadeComponent,
        data: {
            breadcrumb: 'Politica Privacidade',
        }
    },
    {path: 'termoscondicoes', component:TermosCondicoesComponent,
        data: {
            breadcrumb: 'Termos & Condições',
        }
    }
    /**{path: 'produto',component:HomeComponent},    
    {path: 'produto/:id',component:ProdutoComponent, 
                        children:[
                            { path:'', component:DescricaoComponent}, //*component por default
                            { path:'descricao', component:DescricaoComponent},
                            { path:'marcas', component:MarcasComponent}
                        ]},*/
]