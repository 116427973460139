<div class="container mt-3">
  <h3 class="mb-3"><i class="fas fa-envelope-open-text"></i> Contacte-nos</h3>
  
  <!--Area Sucesso-->
  <mat-card *ngIf="this.estadoEnvio===1">
    <div class="row">
      <div class="col-12" align="center">
        <h4>Sucesso</h4>
        <br>
        Obrigado.<br>
        Sua mensagem foi enviada com sucesso.<br>
      </div>
    </div>
  </mat-card>
  <!--Area Sucesso-->
  <!--Area Formulario-->
  <mat-card *ngIf="this.estadoEnvio===0">
    <h5>Precisa de Ajuda?</h5>
    <form [formGroup]="xFContacto" novalidate (submit)="enviarMail()">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="col-12">
            <mat-label>Seu Nome:</mat-label>
            <mat-form-field style="width: 100%;">
                <input matInput 
                type="text" 
                width="100%;" 
                formControlName="xNome">
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>Seu Email:</mat-label>
            <mat-form-field style="width: 100%;">
                <input matInput 
                type="text" 
                width="100%;" 
                formControlName="xEmail">
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>Seu Telefone:</mat-label>
            <mat-form-field style="width: 100%;">
                <input matInput 
                type="text" 
                width="100%;" 
                formControlName="xTelefone">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <textarea rows="10" style="resize: none;" class="form-control " placeholder="Em que podemos ajudar?" formControlName="xMensagem"></textarea>
            </div>
          </div>
        </div>
        <div class="col-12" align="right">
          <button mat-raised-button>Enviar</button>
        </div>
      </div>
    </form>
  </mat-card>
  <!--Area Formulario-->
  <br>
  <!--Area de Localizacao-->
  <h3 class="mb-3 mt-3"><i class="fas fa-map-marker-alt"></i> Onde Estamos</h3>
  <mat-card>
    <div class="row">
      <div class="col-12 col-md-6">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3081.8390168009532!2d-9.13569648437257!3d39.42776462311133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMznCsDI1JzM5LjkiTiA5wrAwOCcwMC42Ilc!5e0!3m2!1spt-PT!2spt!4v1623255820397!5m2!1spt-PT!2spt" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
      <div class="col-12 col-md-6">
        {{this.ws.empresa?.morada}}<br>
        {{this.ws.empresa?.codpostal}}<br>
        {{this.ws.empresa?.localidade}}<br>
        {{this.ws.empresa?.telefone}}<br>
        {{this.ws.empresa?.email}}<br><br><br>
      </div>
    </div>
  </mat-card>
  <!--Area de Localizacao-->
  
</div>
