import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/tools/dialog.service';
import { utilizadorService } from 'src/app/tools/utilizador.service';
import { webService } from 'src/app/tools/webservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dadoscliente',
  templateUrl: './dadoscliente.component.html',
  styleUrls: ['./dadoscliente.component.css']
})
export class DadosclienteComponent implements OnInit {

  constructor(private dialogService:DialogService,
    public c_utilizadorService:utilizadorService,
    public c_WS:webService,
    public c_Router:Router) { }

  ngOnInit(): void {
    
  }
}