<div align="center" style="font-size: 17px;"><strong>Termos e Condições</strong></div><br><br>
<div align="center"><strong>CONDIÇÕES GERAIS  ÁSDEPEÇAS  PARA VENDAS ONLINE</strong></div><br>
1.<strong> GERAL</strong><br>
1.1 Estes termos e condições  aplicam-se à encomenda de produtos ou artigos  através da Loja Online da ÁsdePeças.<br>
http://asdepecas.pt é um site propriedade da Ásdepeças, Lda., com sede em: Rua Casal do Lavradio, nº6 Pavilhão 5, 2500-294 Caldas da Rainha- Portugal. O número de contribuinte da Ásdepeças, Lda   é 506 066 037.
1.2 As condições sob as quais os Produtos são vendidos no website estão descritas abaixo. Ao seleccionar no formulário de encomenda a opção “Li e aceito” e ao submeter a encomenda, aceita e concorda estar sujeito a estas condições.<br>
1.3 A “Ásdepeças” reserva o direito de alterar as condições em qualquer altura sem aviso prévio. No entanto quaisquer alterações não terão efeito sobre encomendas submetidas antes de as novas condições estarem publicadas no website.<br>
1.4 A “Ásdepeças”  não será responsável por qualquer perda ou dano resultantes do uso da Internet, e mais especificamente pela interrupção do serviço, pirataria informática ou intrusão, ou pela presença de vírus informáticos.<br>
1.5 A “Ásdepeças”  reserva-se o direito de recolher dados sobre o utilizador , especificamente através do uso de cookies.<br>
O facto que o utilizador usar os websites www.asdepecas.pt, significa que aceita o uso dos seus dados pessoais , fornecidos no seu registo. Dados estes necessários para processamento das encomendas.Em conformidade com a lei, o utilizador tem o direito de aceder, corrigir ou eliminar dados com ele relacionados. Estes direitos podem ser exercidos por e-mail para a “Ásdepeças”.<br>
1.7 Ao fazer um pedido no nosso website dá-nos garantia que é legalmente capaz de efectuar contratos vinculativos  e tem pelo menos 18 anos de idade<br>
<br>
2. <strong>FAZER UMA ENCOMENDA</strong><br>
2.1 A encomenda pode ser feita:<br>
2.1.1  Online<br>
2.1.2 Por email<br>
2.2  As encomendas só podem ser registadas quando o cliente estiver claramente identificado.<br>
2.3 Todos os Produtos estão sujeitos ao stock existente<br>
2.4 Apenas serão satisfeitas encomendas com morada de entrega em Portugal Continental e arquipélago da Madeira e dos Açores, e Espanha.<br>
2.5 Todas as encomendas  estão sujeitos a aceitação, pelo que assim que  for aceite ser-lhe-á enviado um e-mail de confirmaçao da encomenda.<br>
<br>
3. <strong>ENTREGA</strong><br>
3.1  Os produtos disponiveis serão entregues normalmente no prazo de 10 dias.<br>
3.2  Se o produto não estiver disponivel na quantidade encomendada, ou caso haja ruptura de stok no fabricante/fornecedor  do produto, informá-lo-emos para que possa decidir se pretende continuar com encomenda, encomendar em menor quantidade ou cancelar a encomenda, não podendo posteriormente esta ser anulada ou devolvida, mesmo que ultrapasse o prazo de entrega no artigo 3.3.<br>
3.3 Caso não seja possivél efectuar a entrega dentro do prazo de trinta dia, poderá cancelar a encomenda. Caso tenham sido efectuados pagamentos estes serão devolvidos no prazo de trinta dias.<br>
3.4 Em qualquer caso de um atraso na entrega da mercadoria, não dá o direito ao cliente de exigir qualquer indemenização.<br>
3.5 Não nos responsabilizamos por qualquer roubo, perda ou destruição dos produtos, desde que tenham sido levantados pelo cliente ou entregues no endereço indicado pelo mesmo.<br>
3.6  A “Ásdepeças”  não será responsável por qualquer incumprimento ou atraso no tratamento da encomenda caso o incumprimento ou atraso seja causado, directa ou indirectamente por circunstancias fora do nosso controlo (causas de forças maiores, naturais ou fortuitas) <br>
<br>
5. <strong>PREÇOS , CUSTOS  DE  ENTREGA  E  PAGAMENTO</strong><br>
5.1  Todos os preços estão em Euros. Os preços constantes no website incluem Iva, mas excluem custos de entrega.<br>
5.2 Os preços são firmes na altura em que o cliente faz a encomenda, dentro do limite dos stoks disponiveis. <br>
5.3 Os preços dos produtos e custos das entregas estão sujeitas a alterações, embora estas alterações não afectem as encomendas que já foram confirmadas por nós.<br>
5.4 O pagamento de todos os produtos deve ser feito por Multibanco.<br>
<br>

6. <strong>GARANTIAS</strong><br>
6.1 Todos os produtos usufruem da garantia dada pelos fabricantes de cada um dos nossos produtos,com excepção de produtos usados.<br>
6.1.1 Os produtos usados usufruem de uma garantia de 3 meses.<br>
6.2 A Garantia não cobre:<br>
6.2.1 Danos provocados por utilização incorrecta, abusiva, negligente ou devido a acidente, ou resultantes de factores externos.<br>
6.2.2 Artigos cujo interior tenha sido violado, desmontado, separado ou modificado.<br>
6.2.3 Artigos que sejam montados ou instalados por pessoas não qualificadas para tal, ou em aparelhos para o qual não são destinados.<br>
6.3 É da responsabildade do cliente estar tecnicamente habilitado para instalar ou utilizar as peças ou acessórios adiquiridos, caso não esteja, é favor contactar os nossos serviços técnicos  para que a peça ou acessório não perca a garantia.<br>
<br>
7.  <strong>CONDIÇÕES DE DEVOLUÇÕES</strong><br>

7.1  Em caso de devolução de algum produto que não seja electrico ou electrónico adiquirido na nossa loja online, dispõe do prazo de 14 dias para proceder à sua devolução, a partir da receção da encomenda, nos termos do direito de livre resolução, previsto no Decreto-Lei n.º 24/2014, de 14 de Fevereiro<br>
7.2  No caso de módulos electrónicos ou peças de reparação electricas ou electromecânicas, não são aceites trocas ou devoluções depois de ligados.<br>
7.4 Caso o cliente tenha sido informado que: o produto seria encomendado propositadamente para ele e que não poderia ser devolvido, como mencionado no artigo 3.2, não serão aceites trocas ou devoluções.<br>
7.3  É da responsabilidade do cliente fazer chegar ás nossas instalações o produto em boas condições de acondicionamento, sem terem sido usados ou aplicados, com todos as acessórios incluidos, acompanhado da factura original.<br>
7.4  As despesas de devolução bem como a responsabilidade do transporte ficam a cargo do cliente.<br>
<br>
8.<strong>PROVA</strong><br>
8.1 Os registros electrónicos guardados nos sistemas  de informação da “Ásdepeças” e dos seus parceiros em condições razoalvelmente seguras, serão consideradas como prova de comunicações, encomendas ou pagamentos entre as partes, sem prejuizo da possibilidade que o cliente tem de apresentar prova em contrário por quisquer meios legalmente reconhecidos.<br>
<br>
9. <strong>LEI E JURISDIÇÃO APLICAVEIS</strong><br>

Estes termos e condições standard estão sujeitos á lei Portuguesa.<br>
Em caso de litígio, a jurisdição será atribuida ao tribunal da comarca de Caldas da Rainha.<br>