<div align="left" class="row p-2 justify-content-center">
    <div class="col-12 col-lg-6">
        <i class="fas fa-key fa-2x p-2" style=" vertical-align: middle;"></i><span class="h4">Alterar Password</span>
    </div>
</div>
<div class="row justify-content-center">
    <mat-card class="col-12 col-lg-6">
        <form [formGroup]="xF_AlterarPassword" novalidate (ngSubmit)="AlterarPassword()">
        <mat-card-content>
            <div class="row">
                <div class="col-12"><br>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        placeholder="Introduza a sua Nova Password"
                        type="text" 
                        width="100%;" 
                        formControlName="xIPassword">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12"><br>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        placeholder="Reintroduza a sua Nova Password"
                        type="text" 
                        width="100%;" 
                        formControlName="xRPassword">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions align="right">
            <button mat-button class="btn-danger" type="submit">Alterar Password</button>
        </mat-card-actions>
        </form>
    </mat-card>
</div>
