import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import {ActivatedRoute,Params} from '@angular/router';
import { webService } from '../tools/webservice.service';
import { dadosProdutos } from '../modelos/produtos.model'
import { CookieService } from 'ngx-cookie-service'


@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})

export class ProdutosComponent implements OnInit {

  public xLProdutos:dadosProdutos[]
  public mostrarLoading:boolean = true
  public xLFiltros: []
  public marcasSelecionadas: string[];
  
  public xIdFamilia:number;
  public xIdSubfamilia: number;
  public xDPesquisa: string;


  constructor(private c_route:ActivatedRoute,private c_WS:webService, private cookie:CookieService ) { 
  }
   

  ngOnInit() {
   
    this.c_route.params.subscribe((variavel: Params)=>{
      this.xIdFamilia = variavel.familia
      this.xIdSubfamilia = variavel.subfamilia
      this.xDPesquisa = variavel.dpesquisa

      if(variavel.dpesquisa){
        /**AREA DE PESQUISA */
        this.c_WS.pesquisar(this.xDPesquisa).subscribe((dados:dadosProdutos[])=>{ 
          this.xLProdutos = dados
        },
        (deuErro:any) => {
          this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        })
        this.c_WS.pesquisarFiltros(this.xDPesquisa).subscribe((dados:[])=>{ 
          this.xLFiltros = dados
        },
        (deuErro:any) => {
          this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        },        
        ()=>{ 
          this.mostrarLoading = false
        })
      } else {
        /**Area de Produtos */
        this.c_WS.loadProdutos(this.xIdFamilia,this.xIdSubfamilia).subscribe((dados:dadosProdutos[])=>{ 
          this.xLProdutos = dados
        },
        (deuErro:any) => {
          this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        })
        this.c_WS.loadFiltros(this.xIdFamilia,this.xIdSubfamilia).subscribe((dados:[])=>{ 
            this.xLFiltros = dados     
        },
        (deuErro:any) => {
            this.c_WS.executaSnackBar("Erro 10.2: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        },
        ()=>{ 
          this.mostrarLoading = false
        })
      }
    })
    
    this.marcasSelecionadas = new Array();
  }

  addCarrinho(produto:dadosProdutos){

    if(this.cookie.get('protecao_id').length>0){
      this.c_WS.addCarrinho(produto,1,this.cookie.get('protecao_id')).subscribe((addRes)=>{
        if(addRes===1){
          this.c_WS.executaSnackBar("Produto adicionado com sucesso ao seu carrinho","bg-primary");
          this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta') 
        }
      })
    } else {
      this.c_WS.executaSnackBar("Tem de realizar o Login","bg-danger");
    }
  }

  //Filtro Marca
  filtrarPorMarca(e:any,f_Marca:string){
    this.c_route.params.subscribe((variavel: Params)=>{
      if(variavel.dpesquisa){
        if(e.target.checked){
          this.marcasSelecionadas.push(f_Marca);
        } else {
          this.marcasSelecionadas = this.marcasSelecionadas.filter(m=>m!=f_Marca);
        }    
        this.c_WS.loadProdutosPesquisadosFiltrados(this.xIdFamilia,this.xIdSubfamilia,this.marcasSelecionadas,variavel.dpesquisa).subscribe((dados:dadosProdutos[])=>{ 
          this.xLProdutos = dados
          this.mostrarLoading = false
        },
        (deuErro:any) => {
          this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        })
      } else { 
        if(e.target.checked){
          this.marcasSelecionadas.push(f_Marca);
        } else {
          this.marcasSelecionadas = this.marcasSelecionadas.filter(m=>m!=f_Marca);
        }    
        this.c_WS.loadProdutosFiltrados(this.xIdFamilia,this.xIdSubfamilia,this.marcasSelecionadas).subscribe((dados:dadosProdutos[])=>{ 
          this.xLProdutos = dados
          this.mostrarLoading = false
        },
        (deuErro:any) => {
          this.c_WS.executaSnackBar("Erro 10.1: Ocorreu um erro. Tente Mais Tarde","bg-danger");
        })
      }
    })
  }
}
