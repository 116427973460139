<div mat-dialog-title>Login</div>
<div mat-dialog-content  *ngIf="this.SRP === true then formRecPass else formLogin">
    
    
</div>


 <ng-template #formLogin>
    <form [formGroup]="xFormAutenticacao" novalidate (ngSubmit)="adp_login()">
        <div class="row">
            <div class="col-12">
                <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Email" type="text" width="100%;" formControlName="xUser">
                    <mat-error  *ngIf="xFormAutenticacao.get('xUser').invalid && xFormAutenticacao.get('xUser').touched">Email é campo Obrigatório  </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Password" type="password" width="100%;" formControlName="xPassword">
                    <mat-error  *ngIf="xFormAutenticacao.get('xPassword').invalid && xFormAutenticacao.get('xPassword').touched">Password é campo Obrigatório  </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6 pt-4" align="right">
                <button type="submit" class="btn btn-primary">Login</button>
            </div>
            <div class="col-6 pt-4">
                <button type="button" class="btn btn-light" style="border:1px solid #eeeeee" (click)="fecharLogin()">Cancelar</button>  
            </div>
            <div class="col-12 mt-4" align="center">
                <small><a [routerLink]="" (click)="showRecPass(true)">Esqueceu a Password? Recuperar Password</a></small>
            </div>
        </div>
    </form>
 </ng-template>


 <ng-template #formRecPass>
    <form [formGroup]="xFormRecPW" novalidate (ngSubmit)="adp_rpw()">
        <div class="row">
            <div class="col-12">
                <label>Introduza o seu Email:</label>
                <input type="text" class="form-control" formControlName="xEmailPW">
            </div>
            <div class="col-6 pt-4" align="right">
                <button type="submit" class="btn btn-primary">Recuperar Password</button>
            </div>
            <div class="col-6 pt-4">
                <button type="button" class="btn btn-light" style="border:1px solid #eeeeee" (click)="fecharLogin()">Cancelar</button>  
            </div>
        </div>
    </form>
</ng-template>