import { Component, OnInit} from '@angular/core';
import {dadosProdutos} from '../modelos/produtos.model'
import { Observable, Subject } from 'rxjs';
import { DialogService } from '../tools/dialog.service'
import '../tools/rxjs.extencoes'
import { utilizadorService } from '../tools/utilizador.service'
import { webService } from '../tools/webservice.service';
import { dadosFamilia, dadosMenu } from '../modelos/Menus.model';
import { Router } from '@angular/router';
import * as jQuery from 'jquery';
import 'smartmenus';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public O_RPESQ:Observable<dadosProdutos[]>
  public subjectPesquisa : Subject<string> = new Subject<string>()
  public authEstado: boolean = false
  public totalCarrinho:number

  public xMenu: dadosMenu[];
  public xFamilia:dadosFamilia[] 
  public xSubFamilia: any;
  
  public isDisplay:boolean
  public loadingSF: number;
  
  
  constructor(private dialogService:DialogService,
            public c_utilizadorService:utilizadorService,
            public c_WS:webService,
            public c_Router:Router) { 
              this.xFamilia = []
              this.xMenu = []
              this.xSubFamilia = []
            }
            
  ngOnInit() {
    
    /*this.O_RPESQ = this.subjectPesquisa
      .debounceTime(1000) // funciona como delay. Executa passado x segundos
      .distinctUntilChanged()
      .switchMap((dados:string) => {
        //aqui vamos efetuar a pesquisa
        if(dados.trim() === '') {
          //retornar um Observable Vazio
          return Observable.of<dadosProdutos[]>([])
        }
        return this.c_WS.pesquisa(dados)
      })
      .catch((err:any)=> {
        return Observable.of<dadosProdutos[]>([])
      })*/
      this.c_utilizadorService.Logado()
      this.c_WS.loadMenus().subscribe(dadosMenu=>{
        this.xMenu = dadosMenu
        console.log(this.xMenu)
        jQuery(() => { 
          jQuery("#main-menu").smartmenus({
            noMouseOver:true,
            keepInViewport:false,
            subMenusSubOffsetX: 1,
            subMenusSubOffsetY: -8,
            scrollAccelerate: false,
            showDuration:300,
            scrollStep:50,
            subIndicators:false
          });
        });
      })      
  }
  //####### MENU ###########//
  
  /*Carregar Familias */ 
  familias(idFamilia){
    this.loadingSF = 1
    this.c_WS.loadFamilias(idFamilia).subscribe(
      (dados)=>{ 
          this.xFamilia = dados 
          jQuery(() => { 
            jQuery("#main-menu").smartmenus({
              noMouseOver:true,
              keepInViewport:false,
              subMenusSubOffsetX: 1,
              subMenusSubOffsetY: -8,
              scrollAccelerate: false,
              showDuration:300,
              scrollStep:50,
              subIndicators:false
            });
          });
        },
      (err) => { console.log(err) },
      () => { this.loadingSF = 0 }
      
    )    
  }
  /*Carregar Subfamilias */
  subfamilias(idSubFamilia){
    this.loadingSF = 1
    this.c_WS.loadSubFamilias(idSubFamilia).subscribe((dados)=>{
        this.xSubFamilia = dados
      },
      (err) => { console.log(err) },
      () => { this.loadingSF = 0 }  
    )   
  }

  
  mouseEnter(data : any){
      this.isDisplay= true;
  }

  mouseLeave(data : any){
      this.isDisplay = false;
  }



  pesquisa(dadosPesquisa: string):void{
    //aqui preparamos os dados para a pesquisa
    this.subjectPesquisa.next(dadosPesquisa)
  }
  resetPesquisa():void{
    this.subjectPesquisa.next('')
  }

  //######## AREA DE LOGIN ############//
  abrirLogin():void {
    this.dialogService.abrirLogin()
  }

  logout():void{
    this.c_utilizadorService.logout()
  }


  //####### AREA DE CARRINHO #######//

  

  /*selecionarSubFamilia(dSubFamilia){
    this.c_Router.navigate(['/produtos',dSubFamilia.id])
    this.c_WS.loadProdutos(dSubFamilia.id).subscribe(dados=>{   
      return dados
    })
  }*/
}
