<div align="left" class="row p-2">
    <div class="col-10 col-lg-6">
        <i class="fas fa-map-marker-alt fa-2x p-2" style=" vertical-align: middle;"></i><span class="h4">Moradas de Descarga</span>
    </div>
    <div class="col-2 col-lg-6" align="right">
            <button class="d-none d-lg-block" data-toggle="modal" data-target=".mAddMorada" mat-raised-button>Adicionar Morada</button>
            <button class="d-lg-none" data-toggle="modal" data-target=".mAddMorada" mat-mini-fab color="default" alt="Adicionar Morada">
                <i class="fas fa-plus"></i>
            </button>
    </div>
</div>
<!-- Small modal -->
<div class="row justify-content-center">
    <mat-card class="col-12 mt-2" *ngFor="let dMorada of this.moradaEnvio">
        <mat-card-title>
            <i class="fas fa-map-pin"></i> <span class="h5 ml-2">{{dMorada.descricao}}</span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <div class="col-12">
                    <mat-label><b>Morada</b></mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput readonly value="{{dMorada.morada}}">
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                    <mat-label><b>Localidade</b></mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput readonly value="{{dMorada.localidade}}">
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                    <mat-label><b>Código Postal</b></mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput readonly value="{{dMorada.codpostal}}">
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                    <mat-label><b>País</b></mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput readonly value="{{dMorada.pais}}">
                    </mat-form-field>
                </div>
              </div>
        </mat-card-content>
        <mat-card-actions align="right">
            <button *ngIf = "dMorada.defeito == 0" class="btn btn-info ml-2" (click)="predefinirMorada(dMorada)">Definir como Morada Preferida</button>
            <button *ngIf = "dMorada.defeito == 1" readonly disabled class="btn btn-success" >Morada de Envio Preferida</button>
        </mat-card-actions>
    </mat-card>
</div>

<!--MODAL ADICIONAR MORADA-->
<div class="modal fade mAddMorada" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5  mat-dialog-title>
                    <i class="fas fa-map-pin"></i> <span class="ml-2">Adicionar Morada</span>
                </h5>
            </div>
            <form [formGroup]="xFormAddMorada" novalidate (ngSubmit)="adicionarMorada()">
                <div class="modal-body">
                    <mat-dialog-content>
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="Morada" formControlName="xMorada">
                            <mat-error  *ngIf="xFormAddMorada.get('xMorada').invalid && xFormAddMorada.get('xMorada').touched">Morada é campo Obrigatório  </mat-error>
                        </mat-form-field>
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="Localidade" formControlName="xLocalidade">
                            <mat-error  *ngIf="xFormAddMorada.get('xLocalidade').invalid && xFormAddMorada.get('xLocalidade').touched">Localidade é campo Obrigatório  </mat-error>
                        </mat-form-field>
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="Código Postal" formControlName = "xCodPostal"  mask="0000-000">
                            <mat-error  *ngIf="xFormAddMorada.get('xCodPostal').invalid && xFormAddMorada.get('xCodPostal').touched">Código Postal é campo Obrigatório  </mat-error>
                        </mat-form-field>
                        <mat-form-field style="width: 100%;">
                            <input matInput placeholder="País" formControlName="xPais">
                            <mat-error  *ngIf="xFormAddMorada.get('xPais').invalid && xFormAddMorada.get('xPais').touched">País é campo Obrigatório  </mat-error>
                        </mat-form-field>
                    </mat-dialog-content>
                </div>
                <div class="modal-footer">
                    <button class="mat-raised-button" #closebutton data-dismiss="modal">Cancelar</button>
                    <button class="mat-raised-button mat-primary ml-2" type="submit">Gravar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<!--MODAL ADICIONAR MORADA-->
