import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service'
import * as jQuery from 'jquery';
import 'smartmenus';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'As de Peças';

  constructor(private http: HttpClient, private cookie:CookieService){}

  ngOnInit():void {
   /* jQuery(() => {
      jQuery("#main-menu").smartmenus();
   });*/
  }
}
