<div class="container">
  <div class="row">
    <div class="col-12 mt-2">
      <h4>Os Nossos Destaques</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-3 mt-3" *ngFor="let dProdutos of this.xDestaques">
      <div class="card">
        <div class="row">
          <div class="col-12" style="height: 250px;" align="center">
            <img src="https://imagens.asdepecas.pt/{{dProdutos.referencia}}.jpg" class="img-fluid" style="max-height: 200px;">
          </div>
        </div>
        <div class="card-block pl-2 pr-2">
          <div class="produto_nome" style="font-size: small; height: 50px;">{{dProdutos.produto}}</div> 
          <p class="preco" align='right'>{{dProdutos.preco_civa | currency:'EUR':true}}</p>
        </div>
        <div class="card-footer p-1">
          <div class="row d-flex justify-content-center">
            <div class="col-6" align="right">
              <button class="btn btn-outline-primary" [routerLink]="['./produto_info',dProdutos.id]"  style="width:100%">
                <i class="fas fa-plus-circle"></i> Info
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary" style="width:100%" (click)="addCarrinho(dProdutos)">
                <i class="fas fa-cart-plus"></i> Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>