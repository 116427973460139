import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { utilizadorService } from 'src/app/tools/utilizador.service';

@Component({
  selector: 'app-alterarpassword',
  templateUrl: './alterarpassword.component.html',
  styleUrls: ['./alterarpassword.component.css']
})
export class AlterarpasswordComponent implements OnInit {

  constructor(public cs_utilizadorService:utilizadorService) { }

  ngOnInit(): void {
  
  }

  public xF_AlterarPassword: FormGroup = new FormGroup({
    'xIPassword': new FormControl(null,[Validators.required,Validators.minLength(6)]),
    'xRPassword': new FormControl(null,[Validators.required,Validators.minLength(6)])
  })
  
  //Funcoes
  AlterarPassword():void{
    let iPassword = this.xF_AlterarPassword.value.xIPassword
    let rPassword = this.xF_AlterarPassword.value.xRPassword
    let xUser = this.cs_utilizadorService.get_utilizador.id
    if(iPassword!=rPassword){
      this.cs_utilizadorService.openSnackBar("Password não coincidem",4000,"blue-snackbar")
    } else if(this.xF_AlterarPassword.valid) {
      this.cs_utilizadorService.funcaoAlterarPassword(xUser,iPassword).subscribe((resposta:any)=>{
        if(resposta !== null){
          this.cs_utilizadorService.openSnackBar("A sua password foi alterada com sucesso",4000,"green-snackbar")
        } else {
          this.cs_utilizadorService.openSnackBar("Erro 02: Ocorreu um erro. Tente novamente mais tarde",4000,"red-snackbar")
        }
      })
    } else {
      this.xF_AlterarPassword.markAsTouched()
    }
  }
}
