<div align="center"><strong>POLÍTICA DE PRIVACIDADE</strong></div>
<br>

A “Ásdepeças” compromete-se a proteger a sua privacidade e assume ao titular dos dados um compromisso de privacidade.
Os dados são confidenciais, não sendo partilhados com entidades terceiras.
Os dados recolhidos serão utilizados no âmbito de promoção de ações comerciais e marketing diretos.
Se desejar que os seus dados não sejam utilizados em companhas, por favor contacte-nos por email para o endereço <a href="mailto:asdepecas@gmail.com">asdepecas@gmail.com</a>

O site da “Ásdepeças” e todas as páginas associadas a esta, são geridas pela “Ásdepeças”.
Todo o conteúdo contido nestas páginas não pode ser reproduzido por quaisquer meios, eletrónicos ou mecânicos, ou por qualquer processo de armazenamento de informação sem permissão da “Ásdepeças”
Todos os elementos constituintes das páginas, nomeadamente textos, imagens, ficheiros e design do site estão protegidos pelas disposições legais no âmbito de proteção de Direitos de Autor e não poderão ser utilizados para fins comerciais sem autorização prévia.

O uso de qualquer tipo de informação presente neste site terá que ser requerido através do endereço <a href="mailto:asdepecas@gmail.com">asdepecas@gmail.com</a> para que possa ser, ou não, aprovada a sua utilização em outros elementos.

Toda a informação e dados utilizados no site foram previamente autorizados.
Qualquer tipo de informação enviada por formulário ou por e-mail para este site será apenas utilizada para o site e para mais nenhuma entidade comercial.

Acreditamos que o site não contenha nenhum conteúdo capaz de danificar os diferentes sistemas que podem ser utilizados para o visualizar. Caso algum problema ocorra durante a sua visualização a responsabilidade é única e exclusivamente do visitante e a “Ás de Peças” não terá encargos pelos danos e custos que poderá ter.

Toda a legislação eventualmente disponibilizada neste site não dispensa a consulta do Diário da República, onde o diploma legal em questão tenha sido devidamente publicado. À “Ásdepeças” reserva-se o direito de proceder criminalmente contra os indivíduos ou organizações cujas atuações possam prejudicar este site.

No momento da visita cada utilizador declara ter lido e aceite os Avisos Legais acima. Qualquer esclarecimento sobre problemas ou sobre a declaração de privacidade deverá ser dirigido a asdepecas@gmail.com
<br><br><br>
<div align="center"><strong>Sites cobertos por esta Declaração de Privacidade</strong></div>
<br>
Esta Declaração de Privacidade aplica-se a todos os sites, domínios, portais de informações e registos mantidos pela “Ásdepeças” O processo de manutenção de um site é um processo de evolução, e a  “Ásdepeças” pode decidir, em algum momento futuro, sem aviso prévio, modificar os termos desta Declaração de Privacidade. A utilização deste website ou dos materiais obtidos indica que concorda com a Declaração de Privacidade no momento da utilização. A Declaração de Privacidade será publicada no site existente e onde verificar se houve alguma alteração em todas as visitas.
<br><br><br>
<div align="center"><strong>Informações Recolhidas</strong></div>
<br>
Recolhemos informações sobre si de várias fontes, incluindo: informações recolhidas diretamente através de si, informações coletadas sobre si quando visita o nosso site, quando utiliza nosso serviço ou quando vê os nossos anúncios on-line.
Todas as informações que recolhemos sobre si podem ser combinadas para nos ajudar a adaptar as nossas comunicações.
Em geral, não compartilhamos as informações pessoais do utilizador com terceiros. Somente quando exigido por lei e/ou autoridades públicas.
<br><br><br>
<div align="center"><strong>Controle de Privacidade</strong></div>
<br>
A “Ásdepeças” pode atualizar esta política com frequência.
Se se opõe a essas alterações, deve interromper o uso do nosso site. O facto de continuar a utilizar o nosso site deve indicar o seu reconhecimento e aceitação de tais alterações.


Caso o destinatário aceite a encomenda sem formulação de quaisquer reservas de anomalia na prova de entrega, implica que o mesmo rececionou a carga em conformidade e em perfeitas condições. 
Reclamações realizadas, sem que tenha sido feita qualquer salvaguarda, não poderão ser aceites já que nesta situação a transportadora declinará qualquer responsabilidade. O cliente deverá, portanto, realizar no momento da entrega uma verificação visual para determinar se os produtos rececionados se encontram em conformidade.
<br><br><br>
<div align="center"><strong>RECLAMAÇÕES</strong></div>
<br>
O Utilizador poderá apresentar as suas sugestões ou reclamações à <a href="mailto:asdepecas@gmail.com">asdepecas@gmail.com</a> ou através da opção “Contactos” do site ou através do livro de reclamações online.
