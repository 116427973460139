import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { resultadoEncomenda, resultadoEncomendaLinhas } from 'src/app/modelos/checkoutModel';
import { utilizadorService } from 'src/app/tools/utilizador.service';
import { webService } from 'src/app/tools/webservice.service';

@Component({
  selector: 'app-encomendas',
  templateUrl: './encomendas.component.html',
  styleUrls: ['./encomendas.component.css']
})
export class EncomendasComponent implements OnInit {

  @Input() public idEncomenda:string 

  public dEncomenda: resultadoEncomenda; 
  public dEncomendaLinhas: resultadoEncomendaLinhas[];
  public voltar:number = 0;

  constructor(public us:utilizadorService, public c_WS:webService, private cookie:CookieService) { 

  }

  ngOnInit() { 
   
    /*Carrega Morada de Envio*/
    this.c_WS.dadosEncomendasCabecalhos(this.us.idEncomendaConsultar).subscribe(
      (resEncomenda:resultadoEncomenda)=>{
        console.log(resEncomenda)
        this.dEncomenda = resEncomenda
      }
    );
    this.c_WS.dadosEncomendaLinhas(this.idEncomenda).subscribe(
      (resEncomendaLinha:resultadoEncomendaLinhas[])=>{
        console.log(resEncomendaLinha)
        this.dEncomendaLinhas = resEncomendaLinha
        return true
      }
    );
  }

  voltarEncomendas(){
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    });
    this.us.idEncomendaConsultar = undefined
  }
  

}
