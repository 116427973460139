<div class="container">
        <!--*ngIf="idPedido === undefined"-->
  <div class="row">
    <div class="col-12 subtitulos">
      <i class="fas fa-shopping-cart fa-2x" style="vertical-align: middle;"></i><span class="ml-2">O Meu Carrinho</span>
    </div>
  </div>
  <div class="ordem-compra mt-2" >
    <form [formGroup]="formCompra" novalidate  (ngSubmit)="confirmarCompra()" >
      <!--<mat-card>
        <div class="row">
          <div class="col-12 subtitulos p-0 m-0">
            <i class="material-icons">shopping_cart</i> Carrinho de Compras
          </div>
        </div>
      </mat-card>-->
      <mat-card>
        <div class="form-row d-flex align-content-center p-0 m-0">
          <div class="col-2 d-none d-lg-block"></div>
          <div class="col-5 col-lg-4"><b>Artigo</b></div>
          <div class="col-5 col-lg-2"><b>Quant.</b></div>
          <div class="col-6 col-lg-1" align="right"><b>Preco Unit.</b></div>
          <div class="col-6 col-lg-2" align="right"><b>Total</b></div>
          <div class="col-6 col-lg-1"></div>
        </div>
        <hr>
        <div *ngIf="c_WS.listaCarrinho.length > 0 else carrinhoVazio">
          <div *ngFor = "let dadosItens of c_WS.listaCarrinho; let posicaoAtual = index"  class="row" style="background: #fff; border-bottom: solid 1px #F0F0F0; vertical-align: center;">
        
            <div class="col-2 d-none d-lg-block d-flex align-items-center ">
              <img src="https://imagens.asdepecas.pt/{{dadosItens.referencia}}.jpg" class="img-fluid">
            </div>
            <div class="col-5 col-lg-4 text-left d-flex align-items-center" style="vertical-align: middle;">
              {{dadosItens.produto}}
            </div>

            <div class="col-5 col-lg-2 d-flex align-items-center" align="center">
              <div class="row">
                <div class="col">
                  <button [disabled]="estadoBTQTT" (click)="alterarQTT(dadosItens.referencia,'subtrair',posicaoAtual)" type="button" class="btn btn-link" >-</button>
                  {{dadosItens.qtt}}
                  <button [disabled]="estadoBTQTT" (click)="alterarQTT(dadosItens.referencia,'somar',posicaoAtual)" type="button" class="btn btn-link">+</button>
                </div>
              </div>
            </div>
            
            <div class="col-6 col-lg-1 d-flex align-items-center justify-content-end">
              {{dadosItens.preco_civa | currency: 'EUR'}}
            </div>
            <div class="col-6 col-lg-2 d-flex align-items-center justify-content-end">
              {{dadosItens.total_civa | currency: 'EUR'}}
            </div>
            <div class="col-6 col-lg-1 d-flex align-items-center justify-content-end">
              <button class="btn btn-danger" (click)="removerItem(dadosItens.referencia,posicaoAtual)"><i class="fas fa-trash-alt "></i></button>
            </div>
          </div>
        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="form-row d-flex align-items-center justify-content-end " style="background-color:white;">
            <div class="col-8">
              <div class="row">

              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12 pr-4 p-2" align="right">
                  <strong>Sub Total: {{c_WS.totalSIvaCarrinho | currency:'EUR'}}</strong>
                </div>
                <div class="col-12 pr-4 p-2" align="right"> 
                  <strong>IVA (23%): {{c_WS.totalIVA | currency:'EUR'}}</strong>
                </div>
                <div class="col-12 pr-4 p-2" align="right">
                  <strong>Total: {{c_WS.totalCIvaCarrinho | currency:'EUR'}}</strong> 
                </div>
              </div>
            </div>
        </div>
      </mat-card>
      <mat-card class="mt-2">
        <div class="row">
          <div class="col-6" align="right">
            <button routerLink='/' mat-flat-button style="color: white;" class="bg-primary">
              <i class="material-icons">add_shopping_cart</i> Continuar Compras
            </button>
          </div>
          <div class="col-6">
            <button routerLink='/checkout' mat-flat-button style="color: white;" class="bg-success">
              <i class="material-icons">check_circle</i> Efetuar Compra
            </button>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>

<ng-template #carrinhoVazio>
  <div class="form-row d-flex justify-content-center" style="background: #FAFAFA; padding: 20px; border-bottom: solid 1px #F0F0F0">
  O Seu carrinho está Vazio
  </div>
</ng-template>