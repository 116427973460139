import { Component, OnInit} from '@angular/core';
import {dadosProdutos} from '../modelos/produtos.model'
import { Observable, Subject } from 'rxjs';
import { DialogService } from '../tools/dialog.service'
import '../tools/rxjs.extencoes'
import { utilizadorService } from '../tools/utilizador.service'
import { webService } from '../tools/webservice.service';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProdutoCarrinho } from '../modelos/produtoCarrinho.model';


@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.css']
})
export class CarrinhoComponent implements OnInit {

  public validador:string
  public idPedido:number
  public itensCarrinho: ProdutoCarrinho[]
  public estadoBTQTT:boolean = false
  public totalPortes:number

  constructor(public c_WS:webService,public c_US:utilizadorService,public cookie:CookieService) {
    this.validador= this.c_US.get_utilizador.validador
    this.itensCarrinho = []
  }

  ngOnInit() {
    this.c_WS.mostraCarrinho(this.cookie.get('protecao_id')).subscribe(produtosNoCarrinho=>{
      this.itensCarrinho = produtosNoCarrinho
    });    
  }
  ngOnLoad() {
 
  }


  alterarQTT(xReferencia:string,Operador:string,xPosicao:number){
    this.estadoBTQTT=true
    this.c_WS.alterarQtt(xReferencia,Operador,this.cookie.get('protecao_id'),xPosicao).subscribe(dados=>{
      
      if (this.itensCarrinho[xPosicao].qtt == 0 && Operador==='subtrair') {
        this.itensCarrinho.splice(xPosicao,1)
      } else {
        this.itensCarrinho[xPosicao].qtt = dados.qtt
        this.itensCarrinho[xPosicao].total_civa = dados.total_civa
        
      }
      this.estadoBTQTT=false
      this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta')
    })

  }
  removerItem(xReferencia:string,xPosicao:number){
    if(confirm("Deseja remover este item do Carrinho?")){
      this.c_WS.removerItem(xReferencia,this.cookie.get('protecao_id'),xPosicao).subscribe(dados=>{
        this.itensCarrinho.splice(xPosicao,1)
        this.estadoBTQTT=false
      })
      this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta')
      console.log("PASSOU");
    }
  }
  confirmarCompra() {
    
  }
  
  public formCompra : FormGroup = new FormGroup({
    'morada': new FormControl(null,[Validators.required,Validators.minLength(3),Validators.maxLength(15)]),
    'telefone' : new FormControl(null,[Validators.required,Validators.minLength(1),Validators.maxLength(20)]),
    'complemento': new FormControl(null),
    'fpagamento': new FormControl(null,[Validators.required])
  })

}
