export class dadosLogin{
    public nome: string
    public apelido: string
    public email: string 
    public nphc:string
    public nif:string
    public morada:string
    public codpostal:string
    public localidade:string
    public telefone:string
    public telemovel:string
    public responsavel:string
    public validador:string
    public id:number
}