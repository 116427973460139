import { Component, OnInit, Input } from '@angular/core';
import { utilizadorService } from '../tools/utilizador.service'
import { webService } from '../tools/webservice.service';
import { CookieService } from 'ngx-cookie-service';
import { EncomendaDados } from '../modelos/carrinho.model';
import { checkoutMorada } from '../modelos/checkoutModel';
@Component({
  selector: 'app-sucesso',
  templateUrl: './sucesso.component.html',
  styleUrls: ['./sucesso.component.css']
})
export class SucessoComponent implements OnInit {

  @Input() public idEncomenda:string
  public listaCarrinhoHistorico: EncomendaDados[];
  public DadosME: checkoutMorada; 
  public DadosMFT: checkoutMorada; 

  constructor(public c_utilizadorService:utilizadorService, public c_WS:webService, private cookie:CookieService) { 
    this.listaCarrinhoHistorico = []
  }

  ngOnInit() {
    this.c_WS.mostraCarrinhoHistorico(this.cookie.get('protecao_id'),this.idEncomenda).subscribe(
      (resposta)=>{
        this.listaCarrinhoHistorico = resposta
      }
    )
    this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),'consulta') 
    //Carrega Morada de Envio
    this.c_utilizadorService.mostraMoradaEnvio(this.idEncomenda).subscribe(
      (RespostaME:checkoutMorada)=>{
        this.DadosME = RespostaME
        return true
      }
    );
    //Carrega morada de Faturacao
    this.c_utilizadorService.mostraMoradas(this.cookie.get('protecao_id'),'Faturacao').subscribe(
      (RespostaMFT:checkoutMorada)=>{
        this.DadosMFT = RespostaMFT
        return true
      }
    );
  }

  ngOnLoad() {
    
  }
}
