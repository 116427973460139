import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { webService } from '../tools/webservice.service';


@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css'],
})
export class ContactosComponent implements OnInit {
  public xFContacto: FormGroup = new FormGroup({
    'xNome': new FormControl(null,[Validators.required,Validators.minLength(2)]),
    'xEmail': new FormControl(null,[Validators.required,Validators.minLength(2)]),
    'xTelefone': new FormControl(null,[Validators.required,Validators.minLength(5)]),
    'xMensagem': new FormControl(null,[Validators.required,Validators.minLength(5)])
  })
  estadoEnvio:number = 0

  constructor(public ws:webService) { }

  

  ngOnInit() {
    this.ws.loadDadosEmpresa()
  }

  
  enviarMail():void{
    let nome = this.xFContacto.value.xNome;
    let email = this.xFContacto.value.xEmail;
    let telefone = this.xFContacto.value.xTelefone;
    let mensagem = this.xFContacto.value.xMensagem;
   
    this.ws.enviarEmail(nome,email,telefone,mensagem).subscribe((resEnvio:number)=>{
      if(resEnvio==1){
        this.estadoEnvio = 1
      }
    });
  }
}
