<div class="container">
    <breadcrumb #parent>
      <ol class="breadcrumb">
        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf = "!route.terminal" class="breadcrumb-item">
            <a href="" [routerLink]="[route.url]"><strong>{{route.displayName}}></strong></a>
          </li>
          <li *ngIf="route.terminal" class="breadcrumb.item active"><strong>{{route.displayName}}</strong></li>
        </ng-template>
      </ol>
    </breadcrumb>
</div>