import { Component, OnInit, ViewChild} from '@angular/core';
import {dadosProdutos} from '../../modelos/produtos.model'
import '../../tools/rxjs.extencoes' 
import { utilizadorService } from '../../tools/utilizador.service'
import { webService } from '../../tools/webservice.service';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators, MinLengthValidator } from '@angular/forms';
import { checkoutMorada } from '../../modelos/checkoutModel';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  public estadoEncomenda : string;
  public numeroEncomenda : number = 0;
  public itensCarrinho: dadosProdutos[]
  public moradaEnvio:checkoutMorada[]
  public moradaSelecionada:boolean
  public dados_moradaSelecionada:checkoutMorada
  public mostrarLoading:boolean=false
  public idEncomenda: string;
  public numeroItens: number;
  public valorPortes: number = 0;
  public totalFinal: number;  

 
  public formMEnvio : FormGroup = new FormGroup({
    'xEMorada': new FormControl(null,[Validators.required])
  })
  public formFEnvio : FormGroup = new FormGroup({
    'xMEnvio': new FormControl(null,[Validators.required])    
  })
  public formMPagamento : FormGroup = new FormGroup({
    'xMPagamento': new FormControl(null,[Validators.required])    
  })
  

  //@ViewChild('myStep',null) myStep:any 

  constructor(public c_WS:webService,public c_US:utilizadorService,public cookie:CookieService) {
    this.moradaSelecionada = false
  }

  ngOnInit() {
    this.c_WS.mostraCarrinho(this.cookie.get('protecao_id')).subscribe(produtosNoCarrinho=>{
      this.itensCarrinho = produtosNoCarrinho
      this.totalFinal = Number(this.c_WS?.totalCIvaCarrinho)
    })
    this.c_US.mostraMoradas(this.cookie.get('protecao_id'),'todos').subscribe((dadosMoradas:checkoutMorada[])=>{
      this.moradaEnvio = dadosMoradas
    })
    
  }
   
  enviarCompra():void {
    let moradaEnvio = this.formMEnvio.value.xEMorada
    let metodoEnvio = this.formFEnvio.value.xMEnvio
    let metodoPagamento = this.formMPagamento.value.xMPagamento
    this.mostrarLoading = true
    this.c_WS.checkout(moradaEnvio,metodoEnvio,metodoPagamento,this.cookie.get('protecao_id'))
      .subscribe(resCheckout=>{
        this.estadoEncomenda = resCheckout.resultado
        this.numeroEncomenda = resCheckout.numeroEncomenda
        this.idEncomenda = resCheckout.idEncomenda
        this.mostrarLoading = false
        if(this.estadoEncomenda!='Sucesso') {
          this.c_US.openSnackBar("Ocorreu um Erro. Tente Novamente ou Contacte-nos",4000,"red-snackbar")
        } else {
          console.log(this.numeroEncomenda)
        }
      })
  }
  dadosMorada(event):any {
    this.c_US.mostraMoradas(this.cookie.get('protecao_id'),event.value).subscribe((dadosMorada:checkoutMorada)=>{
      this.dados_moradaSelecionada = dadosMorada
      this.moradaSelecionada = true
     
    })
  }

  carregaMetodoEnvio(event):any{
    this.c_WS.estadoCarrinho(this.cookie.get('protecao_id'),event.value)
    
  }
}
