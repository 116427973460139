<div align="left" class="row p-0 m-0">
    <div class="col-12 m-0">
        <i class="fas fa-user-circle fa-2x p-2 pl-0" style=" vertical-align: middle;"></i><span class="h4">Meus Dados</span>
    </div>
</div>
<div class="row justify-content-center">
    <mat-card class="col-12">
        <mat-card-content>
            <div class="row">
                <div class="col-12 col-lg-4"><br>
                    <mat-label>Nº Contribuinte</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.nif}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-8"><br>
                    <mat-label>Nome</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.nome}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12"><br>
                    <mat-label>Morada</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.morada}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4"><br>
                    <mat-label>Código Postal</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.codpostal}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-8"><br>
                    <mat-label>Localidade</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.localidade}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4"><br>
                    <mat-label>Telefone</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.telefone}}"  
                        type="text" 
                        width="100%;"
                        readonly 
                       >
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-8"><br>
                    <mat-label>Telemóvel</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.telemovel}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12"><br>
                    <mat-label>Responsável de Encomendas</mat-label>
                    <mat-form-field style="width: 100%;">
                        <input matInput 
                        value="{{this.c_utilizadorService.get_utilizador.responsavel}}"  
                        type="text" 
                        width="100%;" 
                        readonly 
                        >
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>