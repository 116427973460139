import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { utilizadorService } from '../../tools/utilizador.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {

  public SRP:boolean
  constructor(public cs_loginService:utilizadorService, public dialogRef:MatDialogRef<LoginComponent>) { }

  ngOnInit() {
  }


  //preparar recepcao para login
  public xFormAutenticacao: FormGroup = new FormGroup({
    'xUser': new FormControl(null,[Validators.required, Validators.email, Validators.minLength(6)]),
    'xPassword': new FormControl(null,[Validators.required,Validators.minLength(6)])
  })
  //form Recuperacao PW
  public xFormRecPW: FormGroup = new FormGroup({
    'xEmailPW': new FormControl(null,[Validators.required, Validators.minLength(5)])
  })

  adp_login():void{
    let user = this.xFormAutenticacao.value.xUser
    let pw = this.xFormAutenticacao.value.xPassword
    if(this.xFormAutenticacao.valid)
    {
      this.cs_loginService.funcaoLogin(user,pw).subscribe((resposta:any)=>{
        if(resposta !== null){
          this.dialogRef.close() 
        } else {
          this.cs_loginService.openSnackBar("Utilizador ou Password Errados",4000,"red-snackbar")
        }
      })
    } else {
      this.xFormAutenticacao.markAsTouched()
    }

  }

  adp_rpw(){
    let xEmail = this.xFormRecPW.value.xEmailPW
    this.cs_loginService.recuperarPW(xEmail).subscribe((resultado:any)=>{
      if(resultado == 1){
        this.cs_loginService.openSnackBar('Foi enviado um email com a sua nova Password.',4000,'green-snackbar');
        this.dialogRef.close()
      }
    })
  }

  fecharLogin(){
    this.dialogRef.close()
  }

  showRecPass(MSRP){
    this.SRP=MSRP
  }

}

