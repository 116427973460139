import { Component, OnInit, ViewChild } from '@angular/core';
import { utilizadorService } from 'src/app/tools/utilizador.service';
import { CookieService } from 'ngx-cookie-service';
import { checkoutMorada } from 'src/app/modelos/checkoutModel';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';



@Component({
  selector: 'app-moradas',
  templateUrl: './moradas.component.html',
  styleUrls: ['./moradas.component.css']
})
export class MoradasComponent implements OnInit {
  
  @ViewChild('closebutton') closebutton;

  public moradaEnvio: checkoutMorada[];
  public moradaDefeito:number = 0;
  public defeitoAtivo = "style='color:green;'";
  public defeitoInativo = "style='color:red;'";

  //registar formulario para adicionar morada
  xFormAddMorada:FormGroup = new FormGroup({
    'xMorada': new FormControl(null,[Validators.required, Validators.minLength(5)]),
    'xCodPostal': new FormControl(null,[Validators.required,Validators.minLength(4)]),
    'xLocalidade': new FormControl(null,[Validators.required,Validators.minLength(2)]),
    'xPais': new FormControl(null,[Validators.required,Validators.minLength(2)])
  })

  

  constructor(public c_utilizadorService:utilizadorService,public cookie:CookieService) { }



  ngOnInit(): void {
    this.c_utilizadorService.mostraMoradas(this.cookie.get('protecao_id'),'todos').subscribe((dadosMoradas:checkoutMorada[])=>{
      this.moradaEnvio = dadosMoradas
    })
  }

  //Carrega Moradas
  dadosMorada(event):any {
    this.c_utilizadorService.mostraMoradas(this.cookie.get('protecao_id'),'todos').subscribe((dadosMoradas:checkoutMorada[])=>{
      this.moradaEnvio = dadosMoradas
    })
  }

  //Criar Morada
  adicionarMorada():void{
    let morada = this.xFormAddMorada.value.xMorada
    let codpostal = this.xFormAddMorada.value.xCodPostal
    let localidade = this.xFormAddMorada.value.xLocalidade
    let pais = this.xFormAddMorada.value.xPais
    if(this.xFormAddMorada.valid)
    {
      this.c_utilizadorService.f_adicionarMorada(morada,codpostal,localidade,pais).subscribe((resposta:any)=>{
        if(resposta == null){
          this.c_utilizadorService.openSnackBar("Erro 04: Ocorreu um erro. Tente novamente mais tarde.",4000,"red-snackbar")
        } else {
          this.c_utilizadorService.openSnackBar("Pedido enviado com sucesso. Morada ficará disponivel num prazo máximo de 24 Horas uteis",4000,"green-snackbar")
          this.xFormAddMorada.reset()
          this.closebutton.nativeElement.click();
        }
      })
    } else {
      this.xFormAddMorada.markAsTouched()
    }
    

  }

  //PreDefinir Morada
  predefinirMorada(xDMorada:any):void{
    let idMorada = xDMorada.id
    this.c_utilizadorService.f_predefinirMorada(idMorada).subscribe((resposta:any)=>{
      if(resposta == null){
        this.c_utilizadorService.openSnackBar("Erro 04: Ocorreu um erro. Tente novamente mais tarde.",4000,"red-snackbar")
      } else {
        this.c_utilizadorService.mostraMoradas(this.cookie.get('protecao_id'),'todos').subscribe((dadosMoradas:checkoutMorada[])=>{
          this.moradaEnvio = dadosMoradas
        })
      }
    });

  }

}

