import { Component, OnInit } from '@angular/core';
import { utilizadorService } from '../tools/utilizador.service'
import { DialogService } from '../tools/dialog.service';
import { webService } from '../tools/webservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conta',
  templateUrl: './conta.component.html',
  styleUrls: ['./conta.component.css']
})
export class ContaComponent implements OnInit {
  ecraConta: number;

  constructor(private dialogService:DialogService,
              public c_utilizadorService:utilizadorService,
              public c_WS:webService,
              public c_Router:Router) { }

  ngOnInit(): void {
    this.ecraConta = 1
  }

  definirEcraConta(idEcra:number){
    this.ecraConta = idEcra
  }
}
