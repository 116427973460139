import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter,AfterViewInit, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { EncomendaDados } from 'src/app/modelos/carrinho.model';
import { utilizadorService } from 'src/app/tools/utilizador.service';
import { webService } from 'src/app/tools/webservice.service';
import { EncomendasComponent } from 'src/app/conta/historico/encomendas/encomendas.component'


@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css']
})

export class HistoricoComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort;
  @Output() idEncomenda = new EventEmitter<string>();

  //,'estado','acao'
  colunasVisiveis:string[] = ['nencomenda','dataEncomenda','total_siva','portes','total_civa','acao'];
  minhasEncomendas: any; 
  idEncConsultar:number=0;
  
  constructor(public us:utilizadorService) {  }

  ngOnInit(): void {
    this.us.loadEncomendas().subscribe((dEncomendas:EncomendaDados[])=>{
      this.minhasEncomendas = new MatTableDataSource(dEncomendas)
      this.minhasEncomendas.sort = this.sort
      this.minhasEncomendas.paginator = this.paginator
      this.paginator._intl.itemsPerPageLabel="Encomendas por Página";
      this.paginator._intl.lastPageLabel = "Ultima Página";
      this.paginator._intl.firstPageLabel = "Primeira Página";
      this.paginator._intl.nextPageLabel = "Próxima Página";
      this.paginator._intl.previousPageLabel = "Página Anterior";
      
    })
  } 

  filtro(texto:string){
    this.minhasEncomendas.filter = texto.trim().toLowerCase();
  }

  consultarEncomenda(validador){
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    });
    this.us.idEncomendaConsultar=validador
    console.log('teste'+this.us.idEncomendaConsultar)
  }

  


}
