import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promocoes',
  templateUrl: './promocoes.component.html',
  styleUrls: ['./promocoes.component.css'],
})
export class PromocoesComponent implements OnInit {

  //carregar a variavel que vai armazenar
  public xPromocoes:string
  public erro:string
  
  constructor() { }

  ngOnInit() {
    /*this.xDPROMOCOES.getOfertasPorCategoria('promocoes').subscribe(
      (dadosCapturados:DadosOferta[])=> {
        
        this.xPromocoes=dadosCapturados
      },
      (deuErro:string)=>{
        this.erro = deuErro
      })*/
  }

}
