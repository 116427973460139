import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http'
import { Observable, empty } from 'rxjs'
import { SERVIDORPHP } from './app.api'

import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { dadosProdutos } from '../modelos/produtos.model'
import { dadosFamilia, dadosMenu } from '../modelos/Menus.model'
import { ProdutoCarrinho } from '../modelos/produtoCarrinho.model'
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service'
import { EncomendaDados } from '../modelos/carrinho.model'
import { dadosEmpresa } from '../modelos/empresa.model'
import { resultadoEncomenda } from '../modelos/checkoutModel'


@Injectable()

export class webService {

    public xFamilias:string
    public validador : string = this.cookie.get('protecao_id')
    public listaProdutos : dadosProdutos[]
    public listaCarrinho:ProdutoCarrinho[]
    public listaCarrinhoHistorico:EncomendaDados[]
    public qttCarrinho: number
    public totalSIvaCarrinho: number
    public totalCIvaCarrinho: number
    public totalIVA:number
    public empresa: dadosEmpresa
    public totalPortes:number
    dadosEnc: resultadoEncomenda
    dadosEncLinhas: resultadoEncomenda[]
    public _opened : boolean = false
    public eDesktop:boolean = false;
    public eTablet:boolean = false;
    public eMobile:boolean = false;


    constructor(private http:HttpClient, private c_route:ActivatedRoute,private _snackBar: MatSnackBar, private cookie:CookieService){
        this.listaProdutos = []
        this.listaCarrinho = []
        this.listaCarrinhoHistorico = []
    }
    
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8    '
       })
    };
    loadMenus():Observable<any>{
        let cabecalhos:Headers = new Headers()
        
        return this.http.post(`${SERVIDORPHP}/webserver/loadMenus.php`,{},this.httpOptions,)
                .map((resposta: dadosMenu)=>{  
                    return resposta
                }) 
    }
    loadFamilias(event):Observable<any>{
        let cabecalhos:Headers = new Headers()
        
        return this.http.post(`${SERVIDORPHP}/webserver/loadFamilias.php`,{idMenu:event},this.httpOptions,)
                .map((resposta: dadosMenu)=>{  
                    return resposta
                }) 
    }

    loadSubFamilias(idSubFamilia):Observable<any>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadSubFamilias.php`,{idsubfamilia:idSubFamilia},this.httpOptions,)
                .map((resposta: dadosFamilia)=>{  
                    return resposta
                }) 
    }

    loadProdutos(idFamilia,idSubFamilia):Observable<dadosProdutos[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadProdutos.php`,{idFamilia:idFamilia,idSubFamilia:idSubFamilia},this.httpOptions,)
                .map((resposta: dadosProdutos[])=>{  
                    return resposta
                })  
    }

    loadProdutosFiltrados(idFamilia:number,idSubFamilia:number,f_Marcas:string[]):Observable<dadosProdutos[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadProdutos.php`,{idFamilia:idFamilia,idSubFamilia:idSubFamilia,f_Marcas:f_Marcas},this.httpOptions,)
                .map((resposta: dadosProdutos[])=>{  
                    return resposta
                }) 
    }

    loadFiltros(idFamilia,idSubFamilia):Observable<[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadFiltros.php`,{idfamilia:idFamilia,idsubFamilia:idSubFamilia},this.httpOptions,)
                .map((resposta: [])=>{  
                    resposta.sort
                    return resposta
                }) 
    }

    loadProdutoInfo(idProduto:number):Observable<dadosProdutos>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadProdutos.php`,{idProduto:idProduto},this.httpOptions,)
                .map((resposta: dadosProdutos)=>{  
                    return resposta
                }) 
    }
    
    pesquisar(dPesquisa):Observable<dadosProdutos[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadProdutos.php`,{dPesquisa:dPesquisa},this.httpOptions,)
                .map((resposta: dadosProdutos[])=>{  
                    return resposta
                })  
    }
    pesquisarFiltros(dPesquisa):Observable<dadosProdutos[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadFiltros.php`,{dPesquisa:dPesquisa},this.httpOptions,)
                .map((resposta: dadosProdutos[])=>{  
                    return resposta
                })  
    }
    loadProdutosPesquisadosFiltrados(idFamilia:number,idSubFamilia:number,f_Marcas:string[],dPesquisa):Observable<dadosProdutos[]>{
        let cabecalhos:Headers = new Headers()
        return this.http.post(`${SERVIDORPHP}/webserver/loadProdutos.php`,{idFamilia:idFamilia,idSubFamilia:idSubFamilia,f_Marcas:f_Marcas,dPesquisa:dPesquisa},this.httpOptions,)
                .map((resposta: dadosProdutos[])=>{  
                    return resposta
                }) 
    }

    loadDadosEmpresa(){
        return this.http.post(`${SERVIDORPHP}/webserver/loadEmpresa.php`,{},this.httpOptions,)
            .subscribe((resposta: dadosEmpresa)=>{  
                this.empresa = resposta
                return resposta
            }) 
    }


    addCarrinho(xproduto:dadosProdutos,qtt:number,xvalidador:string):Observable<any>{
        
        //envia o produto

        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
                    referencia:xproduto.referencia,
                    qtt:qtt,
                    validador:xvalidador,
                    acao:'adicionar'
                },this.httpOptions,)
                .map((resposta: any)=>{ 
                    return resposta
                })
    }

    estadoCarrinho(xvalidador:string,metodoEnvio:string):any{
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            validador:xvalidador,
            acao:'totalcarrinho'
        },this.httpOptions,)
        .subscribe((resposta: any)=>{  
            if(metodoEnvio == 'Envio CTT'){
                this.loadPortes(xvalidador).subscribe((totalPortes:any)=>{
                    this.totalPortes = totalPortes.valor
                    this.qttCarrinho = resposta.qtt
                    this.totalSIvaCarrinho = eval(resposta.totalsiva)
                    this.totalCIvaCarrinho = eval(resposta.totalciva)+(this.totalPortes*1.23)
                    this.totalIVA = resposta.totalciva-resposta.totalsiva                    
                });
            } else {
                this.totalPortes = 0
                this.qttCarrinho = resposta.qtt
                this.totalSIvaCarrinho = eval(resposta.totalsiva)
                this.totalCIvaCarrinho = eval(resposta.totalciva)
                this.totalIVA = resposta.totalciva-resposta.totalsiva
            }
            return true
        })
    }

    mostraCarrinho(xvalidador:string):Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            validador:xvalidador,
            acao:'mostrarcarrinho'
        },this.httpOptions,)
        .map((resposta: any)=>{ 
            this.listaCarrinho = resposta
            return this.listaCarrinho
        })
    }
    mostraCarrinhoHistorico(xvalidador:string,idEncomenda:string):Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            validador:xvalidador,
            idEncomenda:idEncomenda,
            acao:'mostrarcarrinhohistorico'
        },this.httpOptions,)
        .map((resposta: EncomendaDados[])=>{ 
            return resposta
        }) 
    }   
    
    alterarQtt(xReferencia:string,operador:string,xvalidador:string,xposicao:number):Observable<any>{
        let qttPretendida:number = this.listaCarrinho[xposicao].qtt
        
        if (operador == 'subtrair') {
            qttPretendida -= 1
            if(qttPretendida<0){
                qttPretendida=0
            }
        } else {
            qttPretendida++
            
        }
        this.listaCarrinho[xposicao].qtt = qttPretendida

        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            referencia:xReferencia,
            qtt:qttPretendida,
            operacao:operador,
            validador:xvalidador,
            acao:'mudarQTT' 
        },this.httpOptions,)
        .map((resposta: ProdutoCarrinho)=>{ 
            
            if (qttPretendida > 0) {
                this.listaCarrinho[xposicao].total_civa = resposta[xposicao].total_civa
            } else {
                //this.listaCarrinho[xposicao].total_civa = 0
            }
            return this.listaCarrinho[xposicao]
        })

        /*
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            referencia:xReferencia,
            qtt:1,
            operacao:operador,
            validador:xvalidador,
            acao:'mudarQTT' 
        },this.httpOptions,)
        .map((resposta: ProdutoCarrinho)=>{ 
            
            this.listaCarrinho[xposicao].qtt = resposta[xposicao].qtt
            this.listaCarrinho[xposicao].total_civa = resposta[xposicao].total_civa            
            return this.listaCarrinho[xposicao]
        })
        */
    }
    removerItem(xReferencia:string,xvalidador:string,xposicao:number):Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            referencia:xReferencia,
            qtt:0,
            validador:xvalidador,
            acao:'remover' 
        },this.httpOptions,)
        .map((resposta: ProdutoCarrinho)=>{ 
            this.listaCarrinho[xposicao].total_civa = resposta[xposicao].total_civa
            return this.listaCarrinho[xposicao]
        })
    }
    loadPortes(xvalidador:string):Observable<any>{
            return this.http.post(`${SERVIDORPHP}/webserver/loadPortes.php`,{
                validador:xvalidador
            },this.httpOptions,)
            .map((resposta: any)=>{ 
                return resposta
            })
    }

    checkout(moradaEnvio:any,metodoEnvio:any,metodoPagamento:any,xvalidador:string) {       
        return this.http.post(`${SERVIDORPHP}/webserver/carrinho.php`,{
            moradaEnvio:moradaEnvio,
            metodoEnvio:metodoEnvio,
            metodoPagamento:metodoPagamento,
            portes:this.totalPortes,
            validador:xvalidador,
            acao:'checkout' 
        },this.httpOptions,)
        .map((resposta: any)=>{ 
            return resposta
        })
    }

    dadosEncomendasCabecalhos(idEncomenda){
        return this.http.post(`${SERVIDORPHP}/webserver/loadEncomendas.php`,{
            idEncomenda:idEncomenda,
            accao:'carregaEncomenda'
        },this.httpOptions,)
        .map((resultado: resultadoEncomenda)=>{
            return resultado
        })
    }
    
    dadosEncomendaLinhas(idEncomenda:string):Observable<any>{
        return this.http.post(`${SERVIDORPHP}/webserver/loadEncomendas.php`,{idEncomenda:idEncomenda,accao:'carregaEncomendaLinhas'},this.httpOptions,)
            .map((resposta: resultadoEncomenda[])=>{  
                this.dadosEncLinhas = resposta      
                return resposta
            })
    }


    enviarEmail(nome:string,email:string,telefone:string,mensagem:string){
        return this.http.post(`${SERVIDORPHP}/webserver/enviarEmailContactos.php`,{nome:nome,email:email,telefone:telefone,mensagem:mensagem},this.httpOptions,)
        .map((resposta: number)=>{   
            return resposta
        })
    }


    executaSnackBar(mensagem,tipo) {
        this._snackBar.open(mensagem,'',{
                duration:2000,
                panelClass: [tipo]
            })
    }
    
}