<mat-card>
<div align="left" #target class="row p-2">
    <div class="col-10 col-lg-6">
        <i class="fas fa-shopping-basket fa-2x p-2 pl-0" style=" vertical-align: middle;"></i><span class="h4">Histórico de Encomendas</span>
    </div>
</div>
</mat-card>


<div class="col-12" *ngIf="this.us.idEncomendaConsultar != undefined">
    <app-encomendas [idEncomenda] = "this.us.idEncomendaConsultar"></app-encomendas>
</div>

<mat-card *ngIf="this.us.idEncomendaConsultar === undefined" class="mt-2">
    <mat-form-field>
        <input (keyup)="filtro($event.target.value)" matInput placeholder="Filtrar">
    </mat-form-field>
    <div class=""> 
        <mat-table [dataSource]="minhasEncomendas" matSort class="table">
            <!--Definicao de Colunas-->
            <ng-container matColumnDef="nencomenda">
                <mat-header-cell *matHeaderCellDef>Nº Encomenda</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.nencomenda}}</mat-cell>
            </ng-container> 
            <ng-container matColumnDef="dataEncomenda">
                <mat-header-cell  *matHeaderCellDef>Data</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.dataEncomenda}}</mat-cell>
            </ng-container>         
            <ng-container matColumnDef="total_siva">
                <mat-header-cell  *matHeaderCellDef>Total s/IVA</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.total_siva | currency:"EUR"}}</mat-cell>
            </ng-container>         
            <ng-container matColumnDef="portes">
                <mat-header-cell  *matHeaderCellDef>Portes</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.portes | currency:"EUR"}}</mat-cell>
            </ng-container>        
            <ng-container matColumnDef="total_civa">
                <mat-header-cell  *matHeaderCellDef>Total c/IVA</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.total_civa | currency:"EUR"}}</mat-cell>
            </ng-container>   
            <!--<ng-container matColumnDef="estado">
                <mat-header-cell  *matHeaderCellDef>Estado</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.estado}}</mat-cell>
            </ng-container>-->     
            <ng-container matColumnDef="acao">
                <mat-header-cell  *matHeaderCellDef class="justify-content-center">Ações</mat-header-cell> 
                <mat-cell *matCellDef="let element" class="justify-content-center">
                    <button mat-stroked-button (click)="consultarEncomenda(element.id)">Visualizar</button>
                    
                </mat-cell>
            </ng-container>
            <!--Definicao de Colunas-->

            <mat-header-row *matHeaderRowDef = "colunasVisiveis"></mat-header-row>
            <mat-row *matRowDef="let row; columns:colunasVisiveis"></mat-row>

        </mat-table>    
        <mat-paginator [pageSizeOptions]="[10,20,50]"  showFirstLastButtons></mat-paginator>
        
    </div>
</mat-card>